.help-content {
  @include position-relative;

  @media (max-width: 565.98px) {
    margin-top: 0px;
  }

  .contact-card {
    background-color: $white-color;
    border: 1px solid $dark-grey20;
    box-shadow: 0px 30px 30px -10px rgba(40, 58, 93, 0.05);
    border-radius: 15px;
    padding: 24px 28px;
    // margin-bottom: 18px;
    text-align: center;
    height: 100%;
    display: block;
    text-decoration: none !important;

    @media (max-width: 991.98px) {
      margin-bottom: 15px;
      height: auto;
    }

    &:hover {
      border: 1px solid #b4dece;
      background: #f4faff;

      p {
        color: $text-color;
      }
    }

    svg {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: $text-color;
    }

    p {
      font-size: 15px;
      padding: 0px 30px;
      color: #7d889d;
      transition: color 0.3s;
    }
  }

  .faq {
    background-color: $white-color;
    border: 1px solid $dark-grey20;
    box-shadow: 0px 30px 30px -10px rgba(40, 58, 93, 0.05);
    border-radius: 15px;
    padding: 25px 35px;
    width: 100%;
    margin-bottom: 29px;
    margin-top: 29px;

    @media (max-width: 991.98px) {
      margin-top: 0px;
    }

    @media (max-width: 565.98px) {
      padding:20px;
    }

    h2{
      font-weight: normal;
      font-size: 26px;
      line-height: 30px;
      color: #283A5D;
      position: relative;
      margin:30px 0 45px 0;
      padding-bottom: 25px;

      @media (max-width: 565.98px) {
        padding:20px;
        font-size: 20px;
        padding-bottom: 15px;
        margin-top: 10px;
        margin-bottom: 30px;
        padding-left:0px !important;
      }

      &::after{
        content: " ";
        position: absolute;
        width: 80px;
        height: 5px;
        background: linear-gradient(270deg, #55ECB3 0%, #48C898 100%);
        border-radius: 2.5px;
        left: 0;
        bottom: 0;
      }
    }
    .item {
      margin-bottom: 30px;
      padding-left: 75px;
      padding-right: 60px;
      position: relative;

      @media (max-width: 565.98px) {
        margin-bottom: 20px;
        padding-left: 50px;
        padding-right: 0px;
      }

      .answer-contaienr {
        border: none;
        // margin-bottom: 30px;

        .answer-body {
          padding: 0px;
          color: rgba(40, 58, 93, 0.8);
          font-size: 17px;
          margin: 10px 0;

          @media (max-width: 565.98px) {
            font-size:15px;
            line-height:1.7;
          }
        }
      }

      button {
        background: none !important;
        color: $text-color !important;
        padding: 0px;
        font-weight: 400;
        font-size: 20px;
        text-align: left;
        padding-top: 6px;
        line-height: 1.3;

        @media (max-width: 565.98px) {
          font-size:16px;
          line-height:1.5;
        }
      }
      &.expanded {
        background-color:transparent;
      }
    }

    .expanded .toggle-button {
      background: #48c898;

      &:after {
        background: #fff;
        transform: translate(-50%, -50%) rotate(-180deg);
        opacity: 0;
      }

      &:before {
        background: #fff;
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }

    .toggle-button {
      transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
      width: 36px;
      height: 36px;
      background: #e4f7f0;
      border-radius: 10px;
      display: block;
      cursor: pointer;
      position: absolute;
      left: 15px;
      top: 0px;

      @media (max-width: 565.98px) {
        left:0px;
      }

      &:after {
        transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        width: 15px;
        height: 3px;
        background: #48c898;
        border-radius: 10px;
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &:before {
        transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        width: 15px;
        height: 3px;
        background: #48c898;
        border-radius: 10px;
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(270deg);
      }
    }
  }
}
