.my-profile-card{
    padding: 65px 42px;
    @media( max-width:991.98px){
        padding: 24px 28px;
    }
    @media (max-width: 565.98px) {
      padding:20px;
    }
    .btn-primary{
        margin-top: 21px;
    }
}
.logo-uploader{
    @include position-relative;
    @include common-flex-jc;
    background: $light-grey;
    border: 1px dashed #CBD1DB;
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    height: 281px;
    &:focus{
      outline: none;
      border: none;
      box-shadow: none;
    }
    @media( max-width:991.98px){
        margin-bottom: 30px;
        max-width:280px;
        max-height:280px;
        margin:0px auto;
        display: block;
        margin-bottom: 20px;
        border-radius:10px;
        float:none;
    }
    @media (max-width: 565.98px) {
      max-width:100%;
    }
    &-data{
        @include position-relative;
        padding: 0;
        height: 280px;
        text-align: center;
        overflow: hidden;
        @include common-flex-jc;
        flex-direction:column;
        img{
          object-fit: cover;
          max-width: 100%;
        }
        .logo{
            font-weight: normal;
            font-size: 22px;
            line-height: 26px;
            margin: 11px 0 14px 0;
        }
        p{
            font-weight: normal;
            font-size: 17px;
            line-height: 20px;
            margin: 0;
        }
      .loader-wrapper{
        height: 281px !important;
        border-radius: 20px;
        &:focus{
          outline: none;
        }
        .loader{
          width: 100px;
          height: 100px;
          img{
            width: 80px;
          }
        }
        h6{
          font-size: 15px;
          line-height: 1;
        }
      }
      .uploaded-img-wrap{
        @include position-absolute;
        height: 100%;
        background: $light-grey;
        @include common-flex-jc;
        top:0;
        left:0;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .upload-data{
        @include position-absolute;
        height: 100%;
        @include common-flex-jc;
        top:0;
        left:0;
        background-color: rgba(255, 255, 255,.8);
        display: none;
      }
    }
  &:hover{
    .upload-data{
      display: flex !important;
      flex-direction: column;
    }
  }
}
.logo-uploader{
  &:hover{

  }
}
.info-wrapper{
    @include position-relative;
    .asset-management{
        display: none;
    }
    &--header{
        @include position-relative;
        &.sub-account{
            @include common-flex-jsb;
            border-bottom: solid 2px #EFF1F6;
            padding-bottom: 17px;
            margin-bottom: 26px;
            .header3{
                margin-bottom: 0 !important;
            }
            .asset-management{
                @include common-flex;
                @media (max-width: 991.98px) {
                  display: none;
                }

                img{
                    margin-right: 15px;
                    width: 35px;
                    height: 35px;
                    display: block;
                    border-radius: 8px;
                    position: relative;
                    top: 2px;
                    object-fit: cover;
                }
                svg{
                  margin-right: 15px;
                }
                h6{
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 15px;
                    margin:0;
                    color: rgba(40, 58, 93, 0.6016);
                }
                h5{
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 15px;
                    margin: 0;
                    color: #283A5D;
                }
            }
        }
    }
    .header3{
        margin-bottom: 29px;
    }
}
