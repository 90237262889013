.review{
  @include position-relative-fl;
  width: calc(100% - 60px);
  border-radius: 8px;
  border: solid 1px #697EA7;
  @include common-flex-jsb;
  margin: 0 20px 21px 20px;
  padding: 10px 17px;
  @media( max-width:1599.98px){
    width: calc(100% - 28px);
    padding: 10px 10px;
    margin: 0 20px 21px 15px;
  }
  @media( max-width:1299.98px){
    width: 90%;
   display: block;
   margin: 0 15px 15px 15px;
  }
  @media( max-width:991.98px){
    display: block;
    width: calc(100% - 39px);
    text-align: center;
  }
  &-left{
    @include position-relative-fl;
    @include common-flex;
    @media( max-width:1299.98px){
      width: 100%;
      justify-content: center;

    }
    @media( max-width:1199.98px){
      display: block;
      width: 100%;
      text-align: center;
    }
    @media( max-width:767.98px){
      display: block;
      width: 50%;
    }
    span{
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      letter-spacing: -0.1px;
      color: #697EA7;
      position: relative;
      top: 1px;
    }
    .stars{
      margin: 0 5px;
      svg{
        margin-left: 3px;
        &:first-child{
          margin-left: 0px;
        }
      }
    }
  }
  &-right{
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    @media( max-width:1299.98px){
     @include position-relative;
     margin-top: 8px;
     text-align: center;

    }
    @media( max-width:991.98px){
      width: 100%;
      @include position-relative-fl;
    }
    @media( max-width:767.98px){
      width: 50%;
      text-align: right;
    }
  }
}
