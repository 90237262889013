.global-table {
  color: $text-color;
  thead {
    background: #f7faff;
    border-top: 1px solid #eaeff4;
    border-bottom: 2px solid #eaeff4;
    tr {
      th {
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.866667px;
        text-transform: uppercase;
        color: #64728d;
        padding: 22px 8px;
        border: none;
        white-space: nowrap;
        @media (max-width: 1479.98px) {
          padding: 17px 8px;
        }
        &.sortable {
          cursor: pointer;
          position: relative;
          &:after {
            position: relative;
            display: inline-block;
            content: "";
            width: 15px;
            height: 10px;
            top: 1px;
            left: 6px;
            background: transparent url("../../assets/images/global/sort.svg")
              no-repeat;
          }
          &:hover {
            &:after {
              background: transparent
                url("../../assets/images/global/sort-active.svg") no-repeat;
            }
          }
          &.active {
            &:after {
              background: transparent
                url("../../assets/images/global/sort-active.svg") no-repeat;
            }
          }
        }
      }
    }
  }
  tbody {
    background: #ffffff;
    tr {
      th {
        vertical-align: middle;
      }
      td {
        padding: 17px 8px;
        font-weight: normal;
        font-size: 15px;
        line-height: 19px;
        vertical-align: middle;
        // white-space: nowrap;
        @media (max-width: 1479.98px) {
          padding: 17px 8px;
        }
        a {
          color: $blue-color;

          &.pm-link {
            display: block;
          }
        }
        .link-btn {
          color: $blue-color;
          background-color: transparent;
          border: 0;
          padding: 0;
          &.pm-link {
            display: block;
            color: $blue-color;
          }
        }
        .pm-title {
          font-size: 12px;
        }
        .status {
          font-weight: normal;
          font-size: 14px;
          display: flex;
          line-height: 1;
          &.closed-make-readies {
            gap: 5px;
            align-items: center;
            white-space: nowrap;
          }
          img {
            margin-right: 5px;
          }
          svg {
            margin-right: 5px;
          }
          &.completed {
            color: $graph-green;
          }
          &.past-due {
            color: $red-color;
          }
          &.voided {
            color: $paused;
          }
          &.voided2 {
            color: #283a5d;
          }
          &.partially-paid {
            color: $graph-blue;
          }
          &.refunded {
            color: $paused;
          }
          &.quote-completed {
            color: $blue-color;
            svg {
              rect {
                fill: $blue-color;
              }
            }
          }
          &.unpaid {
            color: $text-color;
          }
          &.cancelled{
            color: #ff5d5d;
          }
        }
      }
    }
  }
}

.expanded {
  background-color: #f7fafd;
}
.expanded-row {
  background-color: #f7fafd;
  td {
    border: none;
    padding: 0 15px 15px 15px !important;
  }
}

.row-detail {
  transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
  width: 36px;
  height: 36px;
  background: #e4f7f0;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  position: relative;
  float: right;
  @media (max-width: 767.98px) {
    width: 25px;
    height: 25px;
    border-radius: 6px;
  }
  &:after {
    transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    width: 15px;
    height: 3px;
    background: #48c898;
    border-radius: 10px;
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media (max-width: 767.98px) {
      width: 11px;
      height: 2px;
    }
  }
  &:before {
    transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    width: 15px;
    height: 3px;
    background: #48c898;
    border-radius: 10px;
    display: block;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(270deg);
    @media (max-width: 767.98px) {
      width: 11px;
      height: 2px;
    }
  }
  &.expanded {
    background: #48c898;
    &:after {
      background: #fff;
      transform: translate(-50%, -50%) rotate(-180deg);
      opacity: 0;
    }
    &:before {
      background: #fff;
      transform: translate(-50%, -50%) rotate(0deg);
    }
  }
  &.without-bg {
    background: transparent !important;
    &:after {
      height: 2px;
      background: #283a5d;
    }
    &:before {
      height: 2px;
      background: #283a5d;
    }
    &.expanded {
      background: #283a5d;
      &:before {
        background: #283a5d;
        transform: translate(-50%, -50%) rotate(0deg);
      }
      &:after {
        height: 2px;
        background: #283a5d;
        transform: translate(-50%, -50%) rotate(-180deg);
        opacity: 0;
      }
    }
  }
}

.small-table {
  table-layout: fixed;

  tbody {
    tr {
      border-bottom: solid 1px $light-grey-50;
      td {
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        padding: 16px 20px !important;
        @media (max-width: 565.98px) {
          padding: 12px !important;
        }
        small {
          font-size: 12px;
        }
        &:first-child {
          font-weight: 500;
          position: relative;
          padding-left: 40px !important;
          padding-right: 0 !important;
          &:after {
            @include position-absolute-fl;
            content: "";
            width: 8px;
            height: 8px;
            border-radius: 2px;
            background-color: #b8d5fd;
            top: 50%;
            left: 20px;
            margin-top: -4px;
          }
        }
        a {
          color: $blue-color;
        }
        .link-btn {
          color: $blue-color;
          background-color: transparent;
          border: 0;
          padding: 0;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &-pricing {
    tbody {
      tr {
        td {
          &:last-child {
            text-align: right;
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          padding: 13.5px 20px !important;
          border-top: solid 1px $light-grey-50;
          border-bottom: solid 1px $light-grey-50;
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }
  &-service-cost {
    tbody {
      tr {
        td {
          &:first-child {
            @media (max-width: 565.98px) {
              width: 80%;
              padding-left: 35px !important;
            }
            &:after {
              @media (max-width: 565.98px) {
                left: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.notifications-table {
  margin-bottom: 0px !important;
  .custom-checkbox {
    min-height: auto !important;
  }
  tbody {
    tr {
      &.selected {
        background-color: #f9fdfc;
      }
      td {
        vertical-align: middle !important;
        padding: 21.5px 0.75rem;
        .subject-outer {
          @include common-flex;
          .due-days {
            margin-right: 18px;
          }
        }
      }
      th {
        vertical-align: middle !important;
        padding: 21.5px 0.75rem;
      }
    }
  }
}

.global-btn-outer {
  @include common-flex-jc;
  @media (max-width: 565.98px) {
    display: block;
  }

  .btn {
    margin-left: 15px;
    @media (max-width: 565.98px) {
      margin: 5px 0px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    &:first-child {
      margin-left: 0px;
    }
  }
}

.profile-icon {
  background: #f7faff;
  border-radius: 12px;
  width: 40px;
  height: 40px;
  @include common-flex-jc;
  font-weight: normal;
  font-size: 17px;
  line-height: 20px;
  color: #8f9aae;
  overflow: hidden;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.table td,
.table th {
  border-top: 1px solid #eff2f6;
}
.data-card-data--estimate {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.estimate-cta {
  margin-top: auto;
  padding: 15px;
  .btn {
    width: 100%;
  }
}
.estimate-table {
  position: relative;
  width: 94%;
  left: 3%;
  margin: 2% 0 0 0;
  height: 100%;

  .estimate-thead {
    width: 100%;
    padding: 0 15px;

    .tr {
      width: 98%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;

      .th {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 500;
        &:first-child {
          width: 70%;
        }

        &:last-child {
          width: 30%;
          text-align: right;
          padding-right: 15px;
        }
      }
    }
  }

  .estimate-data {
    width: 100%;
    padding: 15px;
    background-color: #f7f9fd;
    margin: 10px 0;
    border-radius: 10px;
  }

  .estimate-tbody {
    max-height: 140px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #dfe2e7;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #9ba2b1;
      border-radius: 10px;
    }

    .tr {
      width: 98%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: row;
      border-bottom: solid 1px #ebeef2;
      flex-wrap: wrap;
      .td {
        font-size: 14px;
        padding: 7px 0;
        font-weight: 500;
        &:first-child {
          width: 70%;
        }

        &:nth-child(2) {
          width: 30%;
          text-align: right;
          padding-right: 10px;
        }
        &--details {
          width: 100%;
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
          margin-bottom: 10px;
          word-break: break-word;
          &.formatted-description {
            white-space: pre-wrap;
          }
        }
      }
    }
  }

  .estimate-tfoot {
    width: 100%;
    padding: 0 15px;

    .tr {
      width: 96%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-direction: row;

      .td {
        font-size: 16px;
        padding: 9px 7px 5px 0;
        font-weight: 600;
        color: #3a435f;
      }
    }
  }

  .table__address {
    width: 300px;
    white-space: break-spaces;
  }
}
.global-table tbody tr td .status.past-due-expired {
  color: #FF235E !important;
}

.closed-make-readies{
  .global-table{
    thead{
      tr{
        th{
          &:first-child{
            width: 60px;
          }
        }
      }
    }
  }
}

.invoice-small-table {
  tbody {
    tr {
      td {
        font-size: 13px;
        line-height: 15px;
        vertical-align: top;
        border: 0 !important;
        padding: 14px 12px !important;
        @media screen and (min-width: 992px) and (max-width: 1399.98px) { 
          display: block;
          width: 100%;
          padding-left: 40px !important;
        }
        &:first-child {
          @media screen and (min-width: 992px) and (max-width: 1399.98px){
            padding-bottom: 0 !important;
          }
          &:after {
            top: 22px;
          }
        }
        .completion-dates {
          margin-bottom: 0;
          padding-left: 0;
          li {
            font-size: 13px;
            line-height: 18px;
            display: inline-block;
            span {
              color: #3E7CFF;
            }
          }
        }
        &.service-cost-outer {
          padding: 18px 16px 18px 36px !important;
          display: table-cell !important;
          &:first-child {
            &::after {
              top: 25px;
            }
          }
          ul {
            margin-bottom: 0;
            padding-left: 0;
            li {
              list-style-type: none;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 6px;
              margin-bottom: 8px;
              &:first-child {
                margin-bottom: 15px;
              }
              &:last-child, &:only-child {
                margin-bottom: 0;
              }
            }
          }
          .service-cost {
            &-label {
              font-size: 13px;
              line-height: 15px;
              font-weight: 500;
              text-align: start;
              span {
                font-weight: 700;
              }
            }
            &-data {
              font-size: 13px;
              line-height: 15px;
              font-weight: 400;
            }
            &-sub-data {
              font-size: 13px;
              line-height: 15px;
              color: #283A5D;
              font-weight: 400;
              list-style-type: none;
            }
          }
        }
        .review {
          &-rating {
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
            .job-id {
              font-size: 11px;
              line-height: 18px;
              font-weight: 400;
              color: #283A5D;
            }
            .link-btn {
              font-size: 11px;
              line-height: 18px;
              color: #1376FB !important;
              border: 0;
              padding: 0;
              background-color: transparent;
              &:active, &:focus, &:focus-visible {
                background-color: transparent !important;
                outline: none !important;
              }
            }
            .stars {
              margin-top: -4px;
            }
          }
          &-wrapper {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 4px;
            border: solid 1px #D0D5DD;
            border-radius: 8px;
          }
        }
      }
    }
  }
  tfoot {
    td {
      &.total-data {
        color: #283A5D;
      }
    }
  }
}