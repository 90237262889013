.validation-outer{
    @include position-relative;
    text-align: center;
    .validation{
        @include position-relative;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        border-radius: 6px;
        padding: 11px 10px;
        margin-bottom: 18px;
        &.error{
            color: $red-color;
            background-color: lighten($red-color, 28%);
        }
        &.success{
            color: $green-color;
            background-color: lighten($green-color, 58%);
        }
        &.warning{
            color: $orange-color;
            background-color: lighten($orange-color, 48%);
        }
    }

}
.invalid-feedback.text-error{
  display: block !important;
}
.cancel-job-error{
  // color: $red-color;
  background-color: lighten($red-color, 29%);
  td{
    &::after{
      background-color: $red-color !important;
    }
  }
}
