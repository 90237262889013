.ts-inline-calender {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  float: left;

  .rdrMonthAndYearWrapper {
    padding-top: 0px;
    height: 36px;
  }

  .rdrMonth {
    width: 100%;
    padding-bottom: 0px;
  }

  .rdrDay {
    border-radius: 50% !important;
  }

  .rdrSelected {
    border-radius: 50% !important;
  }

  .rdrWeekDay {
    font-weight: bold;
    color: #4E5E6D;
  }

  .rdrDayToday .rdrDayNumber span:after {
    display: none;
  }

  .rdrDayToday {
    .rdrSelected {
      background-color: #1992D8;
    }

  }

  .rdrDayNumber {
    font-size: 11px;
  }

  .rdrMonthAndYearPickers {
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #4E5E6D;
  }

  .rdrPprevButton {
    background-color: transparent;

    &:hover {
      background-color: transparent;
      opacity: .8;
    }

    &:focus {
      outline: none;
    }

    i {
      border: none !important;
      // background-image: url(../assets/images/date-range/prev.svg);
      background-repeat: no-repeat;
      background-position: 0;
      background-color: blue;
      width: 9px;
      height: 16px;

    }
  }

  .rdrNextButton {
    background-color: transparent;

    &:hover {
      background-color: transparent;
      opacity: .8;
    }

    &:focus {
      outline: none;
    }

    i {
      border: none !important;
      // background-image: url(../assets/images/date-range/next.svg);
      background-repeat: no-repeat;
      background-position: 0;
      background-color: blue;
      width: 9px;
      height: 16px;

    }
  }
}

@mixin date-picker-style {
  position: absolute;
  top: auto;
  z-index: 1;
}

.date-picker {
  @include date-picker-style;
}

.tl-date-range {
  @include date-picker-style;
  right: 0;
  z-index: 999;
  background-color: $white-color;
  box-shadow: 0px 0px 40px rgba(40, 58, 93, 0.2);
  border-radius: 10px;
  // box-shadow: 0px 10px 10px rgba(72, 200, 152, 0.25);
  overflow: hidden;

  @media( max-width:565.98px ){
    width: 100%;
  }
  .rdrStaticRanges{
    width: 100%;
    border-top: 1px solid #eff2f7;
  }
  .rdrDefinedRangesWrapper{
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    width:120px !important;
    display: flex;
    align-items: center;
    @media( max-width: 565.98px){
      width: 100% !important;
      display: block;
    }
  }
  .rdrCalendarWrapper {
    border-radius: 10px;
    @media( max-width:565.98px ){
      width: 100%;
    }
    &::before {
      content: "";
      border-top: 10px solid  $white-color;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top-color: $white-color;
      right: 9%;
      position: absolute;
      top: -7px;
      transform: rotate(45deg);
    }

    &::after {
      content: "";
      border-top: 10px solid $white-color;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top-color: $white-color;
      border-left: none;
      right: 9%;
      position: absolute;
      top: -6px;
      transform: rotate(45deg);
    }

    .rdrMonthName {
      text-transform: uppercase;
      color: $text-color;
      letter-spacing: 1px;
      font-weight: 400;
      text-align: center;
      font-size: 15px;
      padding-top: 28px;
    }

    .rdrDay{
      height: 40px;
      span{
        margin: 0 !important;
      }

      &.rdrDayToday{
        // background:red;
      }
    }
    .rdrWeekDay {
      font-size: 15px;
      color: $text-color;
      margin: 0 !important;
    }

    .rdrDayNumber {
      font-size: 15px;
      top:0px;
      bottom: 0px;
      margin-right: 0px !important;
      span {
        color: $text-color;
      }
    }


    .rdrMonthAndYearWrapper {
      position: absolute;
      width: 84%;
      height: auto;
      padding-top:20px;
      @media( max-width: 1599.98px){
        width: 82%;
      }
      @media( max-width: 565.98px){
        width: 100%;
      }
      .rdrMonthAndYearPickers {
        display: none;
      }
    }

    .rdrDayPassive {
      .rdrDayNumber {
        span {
          opacity: .4;

          &:after {
            display: none;
          }
        }

      }

    }

    .rdrMonth {
      padding: 0 35px 20px 35px;
      @media( max-width:1499.98px){
        padding: 0 10px 20px 10px;
        width: 23.667em;
      }
      &:first-child {
        border-right: solid 1px #ebf2ff;
        @media( max-width:565.98px ){
          border-right: none !important;
        }
      }
    }

  }
  .rdrStaticRangeLabel{
    margin-right: 0px !important;
  }
  .rdrStaticRange:focus, .rdrStaticRange:hover .rdrStaticRangeLabel{
    background-color: $primary-color;
    color: $white-color !important;
  }
  .rdrNextPrevButton {
    background: transparent;
    border: none;
    outline: none;

    &:hover {
      background: transparent;
    }

    &.rdrPprevButton {
      background-image: url(../../assets/images/global/prev_month.svg);
      background-color: rgba(255,255,255,.3);
      background-repeat: no-repeat;
      background-position: center;
      width: 30px;
      height: 30px;
    }

    &.rdrNextButton {
      background-image: url(../../assets/images/global/next_month.svg);
      background-color: rgba(255,255,255,.3);
      background-repeat: no-repeat;
      background-position: center;
      width: 30px;
      height: 30px;
    }

    i {
      display: none;
    }
  }

  .rdrDayToday {
    .rdrDayNumber {
      span {
        &:after {
          display: none;
        }
      }
    }
  }

  .rdrEndEdge, .rdrInRange, .rdrStartEdge {
    color: #ECF2FF !important;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange~.rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge~.rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge~.rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected~.rdrDayNumber span {
    color: #000 !important;
  }
  .rdrEndEdge, .rdrEndDayPreview{
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }
  .rdrStartEdge, .rdrStartDayPreview {
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }
  .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge{
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
  }

}
.rdrMonths{
    @media( max-width:565.98px ){
      display: block !important;
    }
  .rdrMonth{
    @media( max-width:565.98px ){
      padding: 0 !important;
      width: 100% !important;
    }
  }
}

.rdrDateRangePickerWrapper{
  @media( max-width: 565.98px){
    display: block !important;
  }
}
.rdrStaticRange{
  .rdrStaticRangeLabel{
    color: $text-color !important;
  }
}
