.global-tooltip{
    border-radius: 6px !important;
    background-color: $text-color !important;
    font-weight: normal !important;
    font-size: 10px !important;
    line-height: 14px !important;
    padding: 8px 10px !important;
    &::before{
      display: none !important;
    }
    &:after{
      border-top-color: $text-color !important;
    }
}

.resident-caused-tooltip {
  max-width: 175px;
  padding: 8px 10px;
  text-align: center;
  font-size: 13px;
  line-height: 18px;
}

.job-id-tooltip {
  background: #101828 !important;
  padding: 8px 16px !important;
  box-shadow: 0px 0px 16px -4px rgba(16, 24, 40, 0.08) !important;
  border-radius: 6px !important;
  ul {
    margin-bottom: 0;
    padding-left: 16px;
    li {
      font-size: 14px;
      line-height: 20px;
    }
  }
}