.base-container {
    @include position-relative;
  }
  .global-content {
    @include position-relative;
    z-index: 99;
    background: rgb(247,250,255);
    background: linear-gradient(0deg, rgba(247,250,255,1) 0%, rgba(236,242,255,1) 100%);
  }
  .global-datawrap {
        padding-top: 0px;
        position: relative;
        min-height:100vh;
        width: calc(100% - 260px);
        left: 260px;
        @media (max-width: 991.98px) {
            width: calc(100% - 0px);
            left: 0;
        }
}
.global-datawrap__inner {
    @include position-relative;
    padding: 0 24px;
    @media (max-width: 1439.98px) {
      padding: 0 15px 0 0;
  }
    @media (max-width: 991.98px) {
        padding: 0px 15px 25px 15px;
        padding-top:92px;
    }
    @media (max-width: 767.98px) {
        padding: 0 15px;
        padding-top:92px;
    }
}

.loader-wrapper{
  @include position-relative;
  height:75vh;
  @include common-flex-jc;
  // background: rgba(247, 250, 255, 0.95);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  .loader{
    width: 160px;
    height: 160px;
    background: rgba(240,246,253,.8);
    border-radius: 20px;
    @include common-flex-jc;
    flex-direction: column;
    &.no-data{
      img{
        width: 60px;
      }
    }
    img{
      width: 80px;
    }
    h6{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 4px;
      margin:10px 0 0 0;
    }
  }
}
