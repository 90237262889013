.dashboard-content {
  @include position-relative;
  // margin-top: 29px;
  @media (max-width: 767.98px) {
    // margin-top: 10px;
  }
  .data-card {
    // min-height: 326px;
    height:calc(100% - 20px);

    @media (max-width: 991.98px) {
      height:calc(100% - 10px);
    }
  }

}
.polls {
  @include position-relative;
}
.resolution-time {
  @include position-relative;
  @include common-flex-jc;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 15px;
  h2 {
    @include position-relative-fl;
    font-weight: normal;
    font-size: 80px;
    line-height: 94px;
    margin-top: 14px;
    span {
      @include position-absolute-fl;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      top: -15px;
      left: 70px;
      color: $graph-green;
    }
  }
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  }
  &--inner{
    @include position-relative;
    border-top: solid 1px $light-grey-50;
    padding: 21px 0;
    h4{
      font-weight: normal;
      font-size: 50px;
      line-height: 56px;
      margin: 0;
      sup{
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: $primary-color;
        position: absolute;
        top: 5px;
      }
    }
    p{
      text-align: left;
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
.with-blur-bg {
  background: transparent url("../../assets/images/global/blur-background.png")
    no-repeat center;
  background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .potential-savings {
      height: unset !important;
    }
  .blur {
    @include position-absolute;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(12px);
    border-radius: 10px;
  }
}
.potential-savings {
  // @include position-absolute;
  height: 100%;
  top: 0px;
  left: 0;
  border-radius: 10px;
  &--header {
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin: 0;
    }
    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $light-grey-70;
    }
  }
  &--data {
    @include position-relative;
    text-align: center;
    width: 80%;
    left: 10%;
    @media(max-width:767.98px){
      width: 100%;
      left: unset;
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      margin: 19px 0 10px 0;
    }
    h5 {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #6a7892;
      margin: 0 0 11px 0;
    }
    p{
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #6a7892;
      margin: 0 0 11px 0;
      span{
        position: relative;
        top: 5px;
      }
    }
  }
}
.after-hour-maintenance {
  padding: 24px 28px;
  @include common-flex-jc;
  flex-direction: column;
  text-align: center;
  .btn-primary {
    font-size: 15px;
    font-weight: normal;
    padding: 8.25px 27.5px;
  }
  h4 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin: 19px 0 10px 0;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #6a7892;
    margin: 0 0 11px 0;
    @media (min-width: 1800px) {
      width: 80%;
    }
    span{
      position: relative;
      top: 6px;
    }
  }
  h5 {
    font-weight: 500;
    font-size: 17px;
    line-height: 18px;
    color: #283a5d;
    margin: 0 0 25px 0;
  }
  .custom-tag {
    margin-left: 8px;
  }
}
#escalationService {
  .btn-primary {
    font-size: 15px;
    font-weight: normal;
    padding: 8.25px 29.15px;
  }
}
.job-types {
  @include position-relative;
  margin-top: 21px;
  &-summary {
    @include position-relative;
    background: rgba(62, 124, 255, 0.0466787);
    border-radius: 20px;
    padding: 31px 18px 18px 18px;
    h4 {
      text-align: center;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      margin-bottom: 25px;
      span {
        font-weight: 500;
        color: $blue-color;
      }
    }
    .job-status {
      width: calc(50% - 5px);
      position: relative;
      background-color: $white-color;
      border-radius: 10px;
      @include common-flex-jc;
      flex-direction: column;
      min-height: 100px;
      float: left;
      h5 {
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        margin: 0;
      }
      p {
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin: 0;
      }
      &.open {
        h5 {
          color: $graph-green;
        }
      }
      &.closed {
        float: right;
      }
    }
  }
  .job-details {
    @include position-relative;
    width: 95%;
    left: 5%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // margin: 21px 0 0 0;
    margin: 0;
    padding: 0;
    @media (max-width: 1599.98px) {
      width: 100%;
      left: 0;
      margin-top: 30px;
    }

    @media (max-width: 1199.98px) {
      display: none;
    }


    li {
      width: calc(100% / 2);
      position: relative;
      list-style: none;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: $dark-grey;
      padding-left: 14px;
      // margin-bottom: 20px;
      margin-bottom: 15px;

      @media (max-width: 1199.98px) {
        width: calc(100%);
      }
      @media (max-width: 991.98px) {
        width: calc(100% / 2);
      }
      @media (max-width: 767.98px) {
        width: calc(100% / 2);
      }
      @media (max-width: 565.98px) {
        width: calc(100%);
      }
      span {
        font-weight: 500;
      }
      &:after {
        @include position-absolute-fl;
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 2px;
        background-color: #b8d5fd;
        top: 5px;
        left: 0px;
      }
    }
  }
}
#escalationServiceMessage {
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width:100%;
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $graph-green;
    background-color: lighten($icon-bg, 40%);
    padding: 15px 51px;
    border-radius: 6px;
    display: block;
    width:100%;
    @media (max-width: 1559.98px) {
      padding: 10px 45px;
    }
    @media (max-width: 1299.98px) {
      padding: 10px 26px;
    }
  }
}
#escalationService {
  transition: all 2s ease-out;
}

.schedule-date-range{
  .tl-date-range{
    @media( max-width:1599.98px ){
      right: unset;
      left: -24px;

    }
    @media( max-width:767.98px ){
      left:0px;

    }

  }

}
.time-range{
  font-size: 12px;
  margin: 0px;
  padding: 0;
  span{
    color: $blue-color;
  }
}
.resolution-time-content{
  text-align: left;
  display:flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content:center;
  .view-reviews{
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $blue-color;
    margin-top: 10px;

  }
}
@media( max-width: 408px ){
.data-card--header--custom{
    display: block !important;
    .header-filter{
      margin-top: 15px;
      .dark{
        width: 100%;
        display: block;

      }
    }
  }
}
.resolution-time--inner{
  .star-rating{
    svg{
      width: 15px !important;
      height: 15px !important;
      margin-left: 3px;
      &:first-child{
        margin-left: 0;
      }
    }
  }
  .job-count{
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    margin-top: 3px;
  }
}

.custom-tag {
  padding: 4px 7px;
  font-size: 12px;
  line-height: 1.2;
  height: 22px;
  font-weight: 500;
  color: $white-color;
  border-radius: 4px;
  background-color: $dark-grey10;
  &.new {
    background-color: #FF6200;
  }
}