.auth-outer{
    @include position-relative;
    @include common-flex-jc;
    background: $primary-color url('../../assets/images/auth/login_bg.jpg') no-repeat 100% center;
    background-size: cover;
    height: 100vh;
    padding: 15px;
}
.auth-card{
    @include position-relative-fl;
    @include common-flex-jc;
    flex-wrap: wrap;
    width: 396px;
    &--inner{
        width: 100%;
        background-color: $white-color;
        border-radius: 25px;
        padding: 62px 38px;
        box-shadow: 0px 50px 50px -30px rgba(40, 58, 93, 0.3);
        @media( max-width:565.98px){
            padding: 62px 30px;
        }
        .logo-wrap{
            @include position-relative;
            margin-bottom: 18px;
        }
    }
    &--content{
        @include position-relative;
        h5{
            font-family: "Open Sans", sans-serif;
            font-weight: normal;
            font-size: 17px;
            line-height: 23px;
            margin:0;
        }
        h4{
            font-family: "Open Sans", sans-serif;
            font-weight: normal;
            font-size: 24px;
            line-height: 33px;
            margin: 0 0 27px 0;
            strong{
                font-weight: 600;
            }
        }
        &.text-left{
            .validation-outer{
                .success{
                    text-align: left;
                }
            }
        }
        input{
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 5000s ease-in-out 0s;
            }
        }
        
    }
    &--back_to_web{
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 1;
        margin-top:20px;
        text-align: center;
        color: lighten($white-color, 90%);

        &:hover{
            text-decoration: none;
            color: $white-color;
        }
    }
}
