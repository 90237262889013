.btn-primary{
    background-color: $primary-color;
    border-radius: 10px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: $white-color !important;
    border: none;
    padding: 10px 30px;
    transition: all .3s;
    border: none !important;
    &:hover{
        color: $white-color;
        opacity: 0.7;
        background-color: $primary-color;
    }
    &:focus{
        color: $white-color !important;
        opacity: 1.0;
        background-color: $primary-color !important;
        box-shadow: none !important;
    }
    &:active{
        color: $white-color !important;
        background-color: $primary-color !important;
    }
    &:disabled{
      color: $white-color !important;
      background-color: $primary-color !important;
      opacity: 1;
      cursor: not-allowed;
  }
}

.btn-secondary{
    background-color: $white-color;
    border-radius: 10px;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: $text-color !important;
    border: solid 1px $dark-grey30;
    padding: 8px 30px;
    transition: all .3s;
    display: flex;
    color:$dark-grey30 !important;
    &:hover{
        color: $dark-grey30;
        opacity: 0.5;
        background-color: $white-color;
    }
    &:focus{
        color: $dark-grey30 !important;
        opacity: 1.0;
        background-color: $light-grey-50;
        box-shadow: none !important;
    }
    &:active{
        color: $white-color !important;
        background-color:$dark-grey30 !important;
    }
}
.btn-clear{
    background-color:$red-color10;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    border: none;
    display: flex;
    padding: 10px 23px;
    path{
      transition:all 0.3s;
    }
    &:hover{
        color: $white-color !important;
        opacity: 1.0;
        background-color: #FF5D5D !important;

        path{
          stroke:#fff !important;
        }
    }
    &:focus{
        color: #616161 !important;
        opacity: 1.0;
        background-color: $red-color10 !important;
        box-shadow: none !important;

        path{
          stroke:#FF5D5D !important;
        }
    }
    &:active{
        color: $red-color10;
        opacity: 1.0;
        background-color: $red-color10;
    }
    i{
        margin-right: 5px;
    }
}

.light-blue-btn{
  background: #F6F9FF;
  border-radius: 10px;
  padding: 9px 23px;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  border: none;
  img{
    margin-right:6px;
  }
  &:hover{
    background: #cedeff;
  }
}

.archived{
  @include common-flex-jc;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
    &-data{
      @include common-flex;
      img{
      margin-right: 6px;
    }
  }
  a{
    color: $blue-color;
    margin-left: 6px;
  }
  &:disabled {
    color: $white-color !important;
    background-color: $primary-color;
    opacity: 1;
  }
}

.excel-btn{
  background: #E5EDFA;
  border-radius: 10px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: none;
  padding: 13px 12px;
  border: solid 1px transparent;
  color: $text-color !important;
  height:50px;

  svg{
    margin-right: 10px !important;
    margin-left: 3px !important;
  }
  &:hover{
    background: #FFFFFF;
    border-color: #E0E7F4;
    box-shadow: 0px 20px 15px -10px rgba(40, 58, 93, 0.05);
    opacity:1.0;
  }
  &:focus{
    background: #fff !important;;
    color:$text-color !important;
    border:1px solid transparent !important;
  }
}


.add-new-btn{
  background: $primary-color;
  border-radius: 10px;
  font-weight: normal;
  color: $white-color !important;
  font-size: 16px;
  line-height: 1;
  border: none;
  padding: 16px 22px;
  border: solid 1px transparent;
  svg{
    margin-right: 5px;
  }
  &:hover{
    color: $white-color !important;
    opacity: 1.0;
    background-color: $primary-color;
    border-color: #E0E7F4;
    box-shadow: 0px 20px 15px -10px rgba(40, 58, 93, 0.05);
  }
  &:focus{
      color: $white-color !important;
      opacity: 1.0;
      background-color: $primary-color;
      box-shadow: none !important;
      border: none !important;
  }
  &:active{
      color: $white-color !important;
      background-color: $primary-color !important;
  }
  &:disabled{
    color: $dark-grey30 !important;
    background-color: $white-color;
    opacity: 1;
  }

}


.delete-btn{
  background-color:#FFEEEE !important;
  font-weight: normal;
  font-size: 13px;
  line-height: 1;
  border: none;
  display: flex;
  padding: 12.5px 23px;
  color: $text-color !important;

  path{
    transition:all 0.3s;
  }

  &:hover{
      color: $white-color !important;
      opacity: 1.0;
      background-color: #FF5D5D !important;
      path {
        fill:#fff !important;
        stroke:#fff !important;
      }
  }
  &:focus{
    color: $text-color !important;
    opacity: 1;
    background-color: $red-color10 !important;
    path {
      fill:#FF5D5D !important;
      stroke:#FF5D5D !important;
    }
  }
  &:active{
      color: $text-color !important;
      opacity: 1;
      background-color: $red-color10 !important;
      path {
        fill:#FF5D5D !important;
        stroke:#FF5D5D !important;
      }
  }
  i{
      margin-right: 5px;
  }
}

.edit-btn{
  background-color:#ECF2FF !important;
  font-weight: normal;
  font-size: 13px;
  line-height: 1;
  border: none;
  display: flex;
  padding: 11.72px 23px;
  color:$text-color !important;
  path{
    transition:all 0.3s;
  }
  &:hover{
      color: $white-color !important;
      opacity: 1.0;
      background-color: #3E7CFF !important;
      path {
        fill:#fff !important;
      }
  }
  &:focus{
    color: $text-color !important;
    opacity: 1.0;
    background-color: #EDF2FE !important;
    path {
      fill:#3E7CFF !important;
    }
  }
  &:active{
    color: $text-color !important;
    opacity: 1.0;
    background-color: #EDF2FE !important;
    path {
      fill:#3E7CFF !important;
    }

  }
  i{
      margin-right: 5px;
  }
}

.download-all-btn{
  color:$blue-color !important;
  font-size: 15px;
  border: none;
  padding: 0;
  svg{
    margin-right: 5px;
  }
  &:active{
    background-color: transparent !important;
    border-color: transparent !important;
    color:$blue-color !important;
  }
}


.btn-block + .btn-block {
    margin-top: 0.8rem;
}

.pdf-btn{
  background-color: #F6F9FF;
  border-radius: 10px;
  font-weight: normal;
  font-size: 13px;
  color: #283A5D !important;
  padding: 11.5px 19px;
  border: none;
  white-space: nowrap;
  span{
    position: relative;
    top: 2px;
  }
  &:disabled{
    color: #617396 !important;
    cursor: not-allowed;
  }
  svg{
    margin-right: 5px;
  }
}
.goback-btn{
  font-size: 13px !important;
  padding:10.25px 14px;
  line-height: 1;
  background-color: #F5F8FF !important;
  border: none !important;
  border-style: none !important;
  border-radius: 8px;
  color: #3E7CFF;
  svg{
    margin-right: 4px;
  }

}

.disapprove-btn {
  background-color:#FFEEEE !important;
  color:  #FF5D5D !important;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  border: 1px solid #FF5D5D;
  padding: 8px 40px;
  &:hover{
    border: 1px solid #FF5D5D !important;
    opacity: .6;
  }
  &:focus{
    border: 1px solid #FF5D5D !important;
    background-color:#FFEEEE !important;
  color:  #FF5D5D !important;
  }
}
.approve-btn {
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  padding: 10px 50px;
}



.btn-gray-outlined{
  background-color: $white-color;
  border-radius: 10px;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: $text-color !important;
  border: solid 1px $dark-grey30;
  padding: 8px 30px;
  transition: all .3s;
  color:$dark-grey30 !important;
  &:hover{
      color: $dark-grey30;
      opacity: 0.5;
      background-color: $white-color;
  }
  &:focus{
      color: $dark-grey30 !important;
      opacity: 1.0;
      background-color: $light-grey-50;
      box-shadow: none !important;
  }
  &:active{
      color: $white-color !important;
      background-color:$dark-grey30 !important;
  }
  span{
    color: #FF4949;
  }
}

.link-btn {
  color: $blue-color;
  background-color: transparent;
  border: 0;
  padding: 0;
  &:focus-visible {
    outline: none;
  }
}