.invoice-summary{
  @include position-relative;
  background: $white-color;
  border: 1px solid #E0E7F4;
  border-radius: 10px;
  margin-bottom: 15px;
  @include common-flex;
  overflow: hidden;
  @media( max-width:767.98px){
    flex-direction: column;
  }
  &--item{
    width: calc(100% / 3);
    border-left: solid 1px #EDF3FF;
    min-height: 81px;
    padding: 23px 19px;
    @include common-flex-jsb;
    @media( max-width:1199.98px){
      padding: 23px 8px;
    }
    @media( max-width:767.98px){
      width: 100%;
      border-left: none;
      border-bottom: solid 1px #EDF3FF;
      min-height: unset;
    }
    &:first-child{
      border-left: none;
    }
    h4{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      margin:0;
      @media( max-width:1199.98px){
       font-size: 14px;
      }
      @media( max-width:991.98px){
        font-size: 15px;
      }
      @media( max-width:565.98px){
        font-size: 16px;
      }
      svg{
        margin-right: 8px;
      }
    }
    h3{
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      text-align: right;
      margin:0;
      @media( max-width:1199.98px){
        font-size: 15px;
       }
      @media( max-width:991.98px){
        font-size: 15px;
      }
      @media( max-width:991.98px){
        font-size: 22px;
      }
      @media( max-width:565.98px){
        font-size: 18px;
      }
    }
    &.past-due{
      color: $red-color;

      &:hover{
        background-color:#FFF7F7;
        opacity: 1 !important;
      }
      &:focus-visible{
        outline: none;
      }
      &.active{
        // background-color: #f3f7ff;
        border: 1px solid rgba(255,93,93,20%);
        border-radius: 10px 0px 0px 10px;
      }
    }
    &.outstanding{
      color: $text-color;
      &:hover{
        background-color:#ffffff;
        opacity: 1 !important;
      }
      &:focus-visible{
        outline: none;
      }
      &.active{
        // background-color: #f3f7ff;
         border: 1px solid rgba(0,123,255,20%);
        border-radius: 0px 10px 10px 0px;
        &:focus-visible{
          outline: none;
        }
      }
    }
    &.paid{
      color: $graph-green;
      &:hover{
        background-color:#F6FCFA;
        opacity: 1 !important;
      }
      &.active{
        background-color: #EDFAF5;
      }
    }
    &.activated{
      background-color: $white-color !important;
    }
    &-50{
      width: 50%;
      border: 0;
      padding: 23px 27px;
      @media( max-width:767.98px){
        width: 100%;
      }
      @media( max-width:575.98px){
        padding: 20px 10px;
      }
      &.past-due{
        background: hsla(0, 100%, 68%, 0.05);
      }
      h4{
        font-size: 22px;
        font-weight: 400;
        display: flex;
        align-items: center;
        @media (max-width: 1199.98px) {
          font-size: 18px;
        }
        @media( max-width:991.98px){
          font-size: 15px;
        }
        svg{
          margin-right: 14px;
          @media( max-width:575.98px){
            margin-right: 8px;
          }
        }
      }
      h3{
        font-size: 25px;
        @media (max-width: 1199.98px) {
          font-size: 22px;
        }
        @media( max-width:991.98px){
          font-size: 15px;
        }
      }
    }
  }
}
.review-not-available{
  background: #F2F5F8;
  border-radius: 8px;
  margin: 0 15px 30px 15px;
  text-align: center;
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: #283A5D;
  padding: 12.5px 0;
}
.form-control-resize{
  width: 15% !important;
  @media( max-width:991.98px){
    width: 100% !important;
  }
}
.closed-button-review-col {
  display: table-cell !important;
}


.invoice-filter{
  @include position-relative;
  .form-control-resize{
    width: 35% !important;
    @media(max-width:1599.98px){
      width: 21% !important;
    }
    @media(max-width:1399.98px){
      width: 19% !important;
    }
  }
  .global-select-resize{
    @media(max-width:1399.98px){
      width: 15% !important;
    }
    .css-1uccc91-singleValue{
      color: #515F7C;
    }
  }
  .custom-switch{
    .custom-control-label{
      @media(max-width:1399.98px){
        white-space: nowrap;
      }
    }
  }
}

.action-button-wrapper{
  @include position-relative;
  @include common-flex-jc;
  margin-top: 16px;
  @media( max-width:1199.98px){
    display: block;
    padding: 0 15px;
    margin-top: 10px;
  }
  @media( max-width:991.98px){
    display: flex;
    margin-bottom: 15px;
  }
  .disapprove-btn{
    margin-left: 15px;
    @media(max-width:1399.98px){
      width:120px;
      @include common-flex-jc;
    }
    @media( max-width:1199.98px){
      width: 100%;
      margin-left: 0;
    }
    @media( max-width:991.98px){
      width: auto;
      margin-left: 15px;
    }
  }
  .approve-btn{
    @media(max-width:1399.98px){
      width:120px;
      @include common-flex-jc;
    }
    @media( max-width:1199.98px){
      width: 100%;
      margin-bottom: 15px;
    }
    @media( max-width:991.98px){
      width: auto;
      margin-bottom: 0;
    }
  }
}

.past-due-days {
  &.warning {
    color: #ff4949;
  }
  font-size: 13px;
  font-weight: 400;
}

.global-table {
  tbody {
    tr.make-ready-job-invoice {
      border-left: 4px solid #1D66FF;
      background: rgba(29, 102, 255, 0.04);
    }
  }
  &.invoice-table{
    thead{
      border-radius: 15px;
    }
    tbody{
      tr {
        td {
          .invoice-number {
            a{
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2;
              color: #283A5D;
            }
            button {
              font-weight: 400;
              font-size: 16px;
              line-height: 1.2;
              color: #283A5D;
              border: 0;
              background-color: transparent;
              padding: 0;
            }
          }
          .make-ready-job {
            height: 15px;
            font-weight: 400;
            font-size: 13px;
            line-height: 15px;
            color: rgba(40, 58, 93, 0.7);
          }
          .link-btn {
            background-color: transparent;
            padding: 0;
            border: 0;
            color: $blue-color;
            &:focus-visible {
              outline: none;
            }
          }
          .action-wrapper {
            @include common-flex;
            gap: 4px;
            .count-btn {
              background: rgba(62, 124, 255, 0.2);
              height: 20px;
              border: 0;
              border-radius: 4px;
              font-size: 12px;
              line-height: 16px;
              color: #1369E9;
              padding: 2px 4px;
            }
          }
          .job-id-wraper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
        }
      }
    }
  }
}
