.polls{
    @include position-relative;
    &-item{
        @include position-relative;
        margin-top: 18px;
        &--question{
            font-weight: normal;
            font-size: 13px;
            line-height: 15px;
        }
        &--progress{
            @include position-relative;
            margin-top:6px;
            .progress-bar{
                border-radius: 10px;
            }
            .progress{
                height: 6px;
                border-radius: 10px;
                background-color:transparent;
                &.poll-1{
                    .progress-bar{
                        background-color: $graph-brown;
                    }
                }
                &.poll-2{
                    .progress-bar{
                        background-color: $graph-blue;
                    }
                }
                &.poll-3{
                    .progress-bar{
                        background-color: $graph-cyan;
                    }
                }
                &.poll-4{
                    .progress-bar{
                        background-color: #9ceacd;
                    }
                }
                &.poll-5{
                    .progress-bar{
                        background-color: $graph-orange;
                    }
                }
                &.poll-6{
                  .progress-bar{
                      background-color: $graph-green;
                  }
              }
            }
        }
    }
}
