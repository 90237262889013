.due-days{
  width:36px;
  height: 36px;
  border-radius: 10px;
  background-color: #ccc;
  @include common-flex-jc;
  font-weight: bold;
  font-size: 18px;
  line-height:1;
  color: $white-color;
  float: left;
  &.due{
    background: linear-gradient(270deg, #FF8A8A 0%, #FF5D5D 100%);
  }
  &.disabled{
    background: linear-gradient(270deg, #C6C6C6 1.83%, #919191 100%);
  }
  &.message{
    background: linear-gradient(270deg, #55ECB3 0%, #48C898 100%);
  }
}

.invoice-status{
  width: 12px;
  height:12px;
  border-radius: 50%;
  background: linear-gradient(270deg, #FF8A8A 0%, #FF5D5D 100%);
  border: 2px solid #FFFFFF;
  box-shadow: 0px 0px 5px rgba(255, 93, 93, 0.496755);
  margin-right: 7px;
}
