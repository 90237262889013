.global-pagination{
  @include position-relative;
  @include common-flex-jsb;
  border-top: solid 1px #EAEFF4;
  padding: 23px 32px;
  z-index: 0;
  @media( max-width:991.98px){
    padding: 14px;
  }
  .page-count{
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    @media( max-width:991.98px){
      display: none;
    }
  }
  .pagination-outer{
    .pagination{
      @media( max-width:991.98px){
        margin-bottom: 0px;
      }
      .page-item{
        margin-right: 5px;
        .page-link{
          border: none;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.35px;
          color: #939CAE;
          border: solid 1px #EBEFF3;
          border-radius: 7px;
          &:hover{
            background-color: transparent;
          }
          &:disabled{
            border: solid 1px transparent !important;
          }
        }
        &.active{
          .page-link{
            background-color: #F4F6FA;
            border: solid 1px #EBEFF3;
            border-radius: 7px;

          }
        }
      }
    }
  }
  .per-page{
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    @include common-flex;
    span{
      @media( max-width:991.98px){
        display: none;
      }
    }
    .global-select{
      margin-left: 10px;
      width: 60px;
      @media( max-width:991.98px){
        width: 80px;
      }
    }
  }

}
