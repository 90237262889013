.global-modal {
  @media (min-width: 767.98px) {
    max-width: 740px;
  }

  &.modal-dialog {
    @media (max-width: 767.98px) {
      margin: 15px !important;
    }
  }

  .modal-content {
    border-radius: 20px !important;
    border: none;

    .modal-header {
      border-bottom: none;
      padding: 46px 35px 0 35px;

      .modal-title {
        position: relative;
        font-weight: normal;
        font-size: 26px;
        line-height: 30px;
      }

      .close {
        position: relative;
        right: 10px;
        z-index: 10;
        opacity: 1;

        @media (max-width: 767.98px) {
          top: 14px;
        }

        &:focus {
          outline: none !important;
          outline-offset: 0;
        }

        &:hover {
          opacity: 0.7;
        }

        span {
          background: transparent url(../../assets/images/global/modal-close.svg) no-repeat;
          width: 35.22px;
          height: 35.22px;
          text-indent: -99999999em;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
    }

    .modal-body {
      padding: 19px 35px 50px 35px;

      .divider {
        position: absolute;
        width: 100%;
        float: left;
        margin: 0;
        left: 35px;
        top: 15px;

        &:after {
          @include position-absolute-fl;
          content: "";
          width: 80px;
          height: 5px;
          top: 0px;
          left: 0;
          background: linear-gradient(270deg, #55ecb3 0%, #48c898 100%);
          border-radius: 2.5px;
        }
      }

      .form-contents {
        @include position-relative;
        padding-top: 48px !important;

        .form-group {
          margin-bottom: 15px;

          label {
            margin-bottom: 5px;
          }
        }

        .light {
          background-color: #f4f6fa !important;
        }

        .modal-left {
          width: 50% !important;
          margin: 5px;

          .btn-secondary {
            width: 150px !important;
            float: right;
          }
        }

        .modal-right {
          width: 50% !important;
          margin: 5px;
          margin-left: 10px;

          .btn-primary {
            width: 150px !important;
            float: left;
            padding: 11px 0px 11px 0px;
            justify-content: center;
          }
        }

        .button-outer-wrap {
          margin-top: 14px !important;

          .btn-secondary {
            padding: 9px 31px;
          }

          .btn {
            width: 150px !important;
            height: 38px !important;
            line-height: 37px !important;
            justify-content: center !important;
            padding: 0 !important;

            &:hover {
              opacity: 0.5;
            }

            &:first-child {
              margin-right: 15px;
            }
          }
        }
      }

      h5 {
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        margin: 18px 0 31px 0;
      }

      .button-outer-wrap {
        @include position-relative;
        @include common-flex-jc;
        margin-top: 43px;

        .btn-primary {
          padding: 10px 30px;
        }
      }
    }
  }
}

.crop-image-modal {
  .crop-area {
    @include position-relative;
    // background-color: $text-color;
    min-height: 364px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.notes-modal {
  @media (min-width: 767.98px) {
    max-width: 314px;
  }

  &.modal-dialog {
    @media (max-width: 767.98px) {
      margin: 15px !important;
    }
  }

  .modal-content {
    border-radius: 20px;
    border: none !important;

    .modal-header {
      .close {
        opacity: 1;

        &:hover {
          opacity: 0.7;
        }

        &:focus {
          outline: none !important;
          outline-offset: 0;
        }

        span {
          background: transparent url(../../assets/images/global/modal-close.svg) no-repeat;
          width: 35.22px;
          height: 35.22px;
          text-indent: -99999999em;
          position: absolute;
          right: 0px;
          top: 0px;
        }
      }
    }

    .modal-body {
      padding: 0px 35px 50px 35px;

      .notes-heading {
        @include common-flex;
        @include position-relative;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;

        img {
          margin-right: 5px;
        }
      }

      p {
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
      }

      .image-wrap {
        @include position-relative;
        @include common-flex;
        flex-wrap: nowrap;

        img {
          border-radius: 10px;
          margin-right: 15px;
          cursor: pointer;
        }
      }

      .pause-note-data-container {
        margin-top: 38px;

        .loader-wrapper {
          float: unset;
          height: unset;
        }
        .hr-md {
          margin: 15px 0 !important;
        }
        .notes-heading__title {
          margin: 0;
          max-height: 250px;
          overflow-x: hidden;
          overflow-y: auto;
          word-break: break-word;
        }
      }
    }
  }
}

.job-description-modal {
  @media (min-width: 991.98px) {
    max-width: 950px;
  }

  &.modal-dialog {
    @media (max-width: 565.98px) {
      margin: 15px !important;
    }
  }

  .modal-content {
    border-radius: 20px;
    border: none;

    .modal-header {
      border-bottom: none;
      padding: 46px 35px 0 35px;

      .modal-title {
        position: relative;
        font-weight: normal;
        font-size: 26px;
        line-height: 30px;
      }

      .close {
        opacity: 1;

        &:focus {
          outline: none !important;
          outline-offset: 0;
        }

        &:hover {
          opacity: 0.7;
        }

        span {
          background: transparent url(../../assets/images/global/modal-close.svg) no-repeat;
          width: 35.22px;
          height: 35.22px;
          text-indent: -99999999em;
          position: absolute;
          right: 30px;
          top: 30px;
        }
      }
    }

    .modal-body {
      padding: 19px 35px 50px 35px;

      .divider {
        position: absolute;
        width: 100%;
        float: left;
        margin: 0;
        left: 35px;
        top: 15px;

        &:after {
          @include position-absolute-fl;
          content: "";
          width: 80px;
          height: 5px;
          top: 0px;
          left: 0;
          background: linear-gradient(270deg, #55ecb3 0%, #48c898 100%);
          border-radius: 2.5px;
        }
      }

      .job-desc-data {
        @include position-relative;

        &-inner {
          height: 419px;
          overflow-y: auto;
          padding-top: 38px;

          &::-webkit-scrollbar {
            width: 10px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #eaeff4;
            border-radius: 10px;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #b8c6d4;
            border-radius: 10px;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #b8c6d4;
          }

          ul {
            margin: 0;
            padding: 0 10px 0 0;
            @include position-relative;

            li {
              position: relative;
              list-style: none;
              font-weight: normal;
              font-size: 16px;
              line-height: 30px;
              margin-bottom: 20px;
              padding-left: 21px;
              word-break: break-word;
              white-space: pre-line;

              &:after {
                @include position-absolute-fl;
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 2px;
                background-color: #b8d5fd;
                top: 10px;
                left: 0px;
              }
            }
          }
        }

        .button-outer-wrap {
          @include position-relative;
          @include common-flex-jc;
          margin-top: 43px;

          .btn-primary {
            padding: 10px 30px;
          }
        }
      }
    }
  }
}

.notes-modal {
  .notification-data {
    @include position-relative;
    @include common-flex-jc;
    margin: 15px 0;
    flex-direction: column;
    text-align: center;

    .notification-icon {
      width: 80px;
      height: 80px;
      background-color: #f2fbf8;
      border-radius: 10px;
      @include common-flex-jc;
    }

    h4 {
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      margin: 21px 0 5px 0;
    }

    p {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: #68758d;
    }
  }

  .notification-btn-wrap {
    @include position-relative;

    .btn-secondary {
      padding: 9px 53px;
      color: #697ea7 !important;

      &:hover {
        // background:#697EA7 !important;
        // color:#fff !important;
        opacity: 0.5;
      }
    }
  }
}

.review-job-modal {
  padding: 10px;
  width: auto !important;
  .modal-title {
    padding-right: 12px;
  }
}

.review-modal {
  @media (min-width: 767.98px) {
    max-width: 708px;
  }

  &.modal-dialog {
    @media (max-width: 767.98px) {
      margin: 1.75rem auto !important;
    }
  }

  .modal-content {
    border-radius: 20px;
    border: none;

    .modal-header {
      border-bottom: none;
      padding: 28px 35px 16px 35px;

      .modal-title {
        position: relative;
        font-weight: normal;
        font-size: 26px;
        line-height: 30px;
      }

      .close {
        opacity: 1;
        background-color: transparent;

        &:hover {
          opacity: 0.7;
        }

        &:focus {
          outline: none !important;
          outline-offset: 0;
        }

        span {
          background: transparent url(../../assets/images/global/modal-close.svg) no-repeat;
          width: 35.22px;
          height: 35.22px;
          text-indent: -99999999em;
          position: absolute;
          right: 30px;
          top: 25px;
        }
      }
    }

    .modal-body {
      padding: 0 35px 15px 35px;

      .review-modal-data {
        @include position-relative;
        border-top: 1px solid #e1ebe8;

        &--top {
          @include position-relative;
          @include common-flex;
          padding: 28px 0px 21px 0;

          .rating {
            @include position-relative-fl;
            width: 25%;
            text-align: center;
            border-right: solid 1px #e1ebe8;

            h3 {
              font-weight: bold;
              font-size: 60px;
              line-height: 70px;
              margin: 0;
            }

            .stars {
              @include position-relative;

              img {
                width: 19px;
                margin-left: 4px;

                &:first-child {
                  margin-left: 0px;
                }
              }
            }
          }

          .address {
            @include position-relative-fl;
            width: 70%;
            left: 5%;

            address {
              font-weight: normal;
              font-size: 20px;
              line-height: 29px;
              color: #66748d;
              margin: 0;

              h4 {
                color: $text-color;
                font-weight: bold;
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 8px;
              }
            }
          }
        }

        &--bottom {
          @include position-relative;
          @include common-flex;
          padding: 18px 37px 26px 37px;
          background: rgba(62, 124, 255, 0.03);
          border: 1px solid #e1ebe8;
          border-radius: 10px;

          .review-content {
            @include position-relative;

            p {
              font-weight: 400;
              font-size: 20px;
              line-height: 32px;
              margin-bottom: 18px;
            }

            .date {
              font-weight: normal;
              font-size: 16px;
              line-height: 25px;
              opacity: 0.7;
              font-style: italic;
            }

            &--name {
              @include position-relative;
              font-weight: normal;
              font-size: 18px;
              line-height: 28px;
              margin-bottom: 3px;
            }

            &--data {
              font-weight: 500;
              font-size: 20px;
              line-height: 28px;
              margin-bottom: 13px;
            }

            &--rating {
              @include position-relative;
              @include common-flex-jsb;

              .star-rating {
                display: flex;
                align-items: center;

                .stars {
                  svg {
                    margin-right: 4px;
                  }
                }

                .rating {
                  font-weight: bold;
                  font-size: 18px;
                  line-height: 25px;
                  margin-top: 3px;
                }
              }

              .time-stamp {
                font-style: italic;
                font-weight: normal;
                font-size: 16px;
                line-height: 25px;
              }
            }
          }
        }
      }

      .tenant-review-outer {
        padding-right: 8px;
        max-height: 480px;
        overflow-y: auto;
        margin-bottom: 16px;
        .tenant-review-card {
          &:last-child {
            margin-bottom: 0;
          }
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
            background: #F2F4F7;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb {
            background: #D0D5DD;
            border-radius: 4px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #D0D5DD;
        }
      }

      .tenant-review-card {
        border: solid 1px rgba(29, 102, 255, 0.2);
        background-color: rgba(29, 102, 255, 0.051);
        border-radius: 6px;
        padding: 22px 16px 16px;
        @include common-flex-jc;
        flex-direction: column;
        margin-bottom: 16px;
        .rating-wrapper {
            @include common-flex;
            gap: 3px;
            margin-bottom: 10px;
            svg {
                width: 22px;
                height: 22px;
                path {
                    fill: #FFA800;
                }
            }
        }
        h5 {
            font-size: 15px;
            line-height: 20px;
            margin-bottom: 10px;
            font-weight: 600;
            color: #000;
            text-align: center;
        }
        p {
            font-size: 12px !important;
            line-height: 16px !important;
            margin-bottom: 0 !important;
            font-weight: 400 !important;
            color: rgba(0, 0, 0, 0.698) !important;
            text-align: center !important;
        }
        h6 {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 4px;
            font-weight: 400;
            color: #000;
            text-align: center;
            word-break: break-word;
        }
      }

      .review-modal-data--top_user_data {
        h5 {
          font-weight: normal;
          font-size: 18px;
          color: $text-color;
          margin: 0;
        }

        h4 {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          margin: 4px 0 0 0;
        }

        p {
          font-weight: normal;
          font-size: 18px;
          line-height: 29px;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.image-slider-modal {
  max-width: 1015px !important;

  @media (min-width: 991.98px) {
    max-width: 950px;
  }

  &.modal-dialog {
    @media (max-width: 767.98px) {
      margin: 15px !important;
    }
  }

  .modal-content {
    border-radius: 20px;
    border: none;
    background-color: transparent;

    .modal-header {
      border-bottom: none;
      position: absolute;
      right: 30px;
      top: 30px;
      z-index: 999;

      @media (max-width: 991.98px) {
        right: 50px;
      }

      .close {
        opacity: 1 !important;

        &:hover {
          opacity: 0.7;
        }

        &:focus {
          outline: none !important;
          outline-offset: 0;
        }

        span {
          background: transparent url(../../assets/images/global/modal-close.svg) no-repeat;
          width: 35.22px;
          height: 35.22px;
          text-indent: -99999999em;
          position: absolute;
          right: 0px;
          top: 0px;

          @media (max-width: 991.98px) {
            width: 36px;
            height: 36px;
            background-size: cover;
          }

          @media (max-width: 565.98px) {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .modal-body {
      padding: 0;

      @media (max-width: 991.98px) {
        padding: 0 30px;
      }

      .image-slider-data {
        height: 718px;
        @include position-relative;

        .slider-wrapper {
          border-radius: 20px;

          .slide {
            img {
              object-fit: contain;
              height: 685px;

              @media (max-width: 1299.98px) {
                height: 600px;
              }

              @media (max-width: 991.98px) {
                height: 475px;
              }

              @media (max-width: 565.98px) {
                height: 215px;
              }
            }
          }
        }

        .control-arrow {
          @media (max-width: 565.98px) {
            top: 30%;
          }
        }

        .control-next {
          @media (max-width: 565.98px) {
            right: -10px;
          }
        }

        .control-prev {
          @media (max-width: 565.98px) {
            left: -10px;
          }
        }
      }

      .thumbs-wrapper {
        display: flex;
        justify-content: center;
      }

      .thumbs {
        margin-bottom: 0px !important;
      }

      .thumb {
        width: 75px !important;
        height: 75px;
        border: none !important;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 15px;
        position: relative;
        padding: 0;

        @media (max-width: 565.98px) {
          width: 45px !important;
          height: 45px;
          margin-right: 10px;
        }

        &.selected {
          &:after {
            @include position-absolute;
            height: 100%;
            content: "";
            background-color: rgba(72, 200, 152, 0.5);
            top: 0;
            left: 0;
          }
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 10px !important;
        }
      }
    }
  }
}

.property-manager-modal {
  .modal-header {
    padding: 51px 35px 0 35px !important;
  }

  .modal-body {
    padding: 22px 35px 50px 35px !important;

    .divider {
      top: 19px !important;
    }
  }
}

.service-agreement-modal {
  &.job-description-modal {
    .modal-content {
      .modal-header {
        @media (max-width: 565.98px) {
          padding: 46px 20px 0 20px;
        }
      }

      .modal-body {
        padding-top: 35px;

        @media (max-width: 565.98px) {
          padding: 35px 20px 50px 20px;

          .divider {
            left: 20px;
          }
        }
      }
    }
  }

  &__sub-title {
    font-size: 14px;
    line-height: 20px;
    margin: 4px 0px 0px;
  }

  &__wrapper {
    height: 500px;
    overflow-y: auto;
    padding-top: 22px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #eaeff4;
      border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #b8c6d4;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #b8c6d4;
    }

    p {
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__button {
    margin-top: 40px;
    text-align: center;

    .btn {
      margin: 0 auto;

      &.disabled {
        background-color: $white-color !important;
        border: solid 1px $dark-grey30 !important;
        cursor: not-allowed;
      }
    }
  }
}

.paused-notes-modal {
  &.modal-dialog {
    @media (min-width: 567.98px) {
      max-width: 500px;
    }

    @media (max-width: 767.98px) {
      margin: 1.75rem auto !important;
    }

    @media (max-width: 565.98px) {
      margin: 15px !important;
    }
  }

  &__button {
    max-width: 258px;
    margin: 0 auto;
    margin-top: 30px;
  }

  &__image {
    &-wrap {
      @include common-flex;
      flex-wrap: wrap;
      margin: 0 -8px;
      @media (max-width: 565.98px) {
        margin: 0 -4px;
      }
    }

    width: 70px;
    height: 70px;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 8px;
    margin-bottom: 8px;

    @media (max-width: 565.98px) {
      width: 50px;
      height: 50px;
      margin: 0 4px;
      margin-bottom: 8px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }

  .notes-heading__title {
    padding-top: 6px;
    clear: both;
  }
}

.ask-question-modal {
  @media (min-width: 991.98px) {
    max-width: 580px;
  }

  &.modal-dialog {
    @media (max-width: 565.98px) {
      margin: 15px !important;
    }
  }

  .modal-header {
    .close {
      top: 10px;
    }
  }

  .modal-body {
    padding: 19px 35px 30px 35px !important;
  }
}

.ask-question-data {
  @include position-relative;
  margin-top: 15px;

  .custom-textarea {
    @include position-relative;
    min-height: 185px;
    resize: horizontal;
    font-size: 13px;
    background: #f4f6fa;
    border-radius: 10px;
  }

  .btn-action-data {
    @include common-flex-jc;
    @include position-relative;
    gap: 15px;
    margin-top: 15px;
  }

  p {
    margin-top: 20px;
    text-align: center;
  }
}

.job-notes-modal {
  @media (min-width: 768px) {
    width: 690px;
  }

  .modal-body {
    .loader-wrapper {
      height: 220px !important;
    }

    .new-note-data {
      @include position-relative;
      margin-top: 15px;
      overflow: hidden;
      max-height: 350px;
      overflow-y: auto;
      padding-right: 20px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f4f6fa;
      }

      &::-webkit-scrollbar-thumb {
        background: #d8e1f2;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: darken($color: #d8e1f2, $amount: 20);
      }
    }

    .customer-note-wrapper {
      @include position-relative;
      margin-top: 15px;
      border-radius: 8px;
      border: 1px solid rgba(40, 58, 93, 0.2);
      background: rgba(40, 58, 93, 0.02);
      padding: 15px;

      .customer-note-top {
        @include position-relative;
        @include common-flex-jsb;

        .customer-note-time {
          font-size: 14px;
          font-style: italic;

          a {
            cursor: auto;

            &:hover {
              color: #007bff;
              opacity: 1;
            }
          }
        }

        .green-text {
          color: #48c898;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1px;
        }
      }

      .customer-note-bottom {
        p {
          color: #6d6d6d;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 0;
          padding: 0;
        }
      }

      &.new-note {
        border: 1px solid rgba(72, 200, 152, 0.3);
        background: rgba(72, 200, 152, 0.05);

        .customer-note-bottom {
          p {
            font-weight: 500;
          }
        }
      }
    }
  }
}

.hvac-unit-modal {
  max-width: 794px;
  @include mobile {
    max-width: 545px;
  }
  .modal {
    &-content {
      border: 0;
      border-radius: 24px;
      .modal-header {
          padding: 35px 37px 28px;
          border: 0;
          @include md-tablet {
              padding: 26px 17px 18px;
          }
          .modal-title {
              font-size: 30px;
              line-height: 40px;
              font-weight: 500;
              color: #000;
              @include md-tablet {
                  font-size: 24px;
                  line-height: 32px;
              }
          }
          .close {
            font-size: 36px;
            font-weight: 400;
            line-height: 1;
            color: #000;
            opacity: 1;
            padding: 0;
            margin: 0;
            width: 32px;
            height: 32px;
            @include common-flex-jc;
          }
      }
      .modal-body {
          padding: 0 36px 0 32px;
          @include md-tablet {
              padding: 0 18px 28px;
          }
          .unit-box {
              background-color: #F2FBF8;
              border: solid 1px #D2E9E1;
              border-radius: 8px;
              padding: 20px;
              margin-bottom: 25px;
              @include md-tablet {
                  padding: 15px;
              }
              .hvac-unit-details-content {
                .form {
                    &-group-common {
                        margin-bottom: 16px !important;
                    }
                    &-label {
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 4px;
                        color: #101828;
                    }
                    &-data {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: 500;
                        color: #101828;
                    }
                }
                .no-margin {
                    margin-bottom: 0 !important;
                }
                .unit-data-divider {
                    border: dashed 1px #C7E3D9;
                    margin-bottom: 16px;
                }
              }
          }
          .hvac-tabs {
              border-radius: 10px;
              overflow: hidden;
              .nav {
                  background-color: #F7F7F7;
                  display: flex;
                  flex-wrap: nowrap;
                  border: 0;
                  &-item {
                      flex: 1 1 100%;
                      button {
                          background-color: #F7F7F7;
                          width: 100%;
                          border-radius: 0;
                          color: #000 !important;
                          font-weight: 400;
                          padding: 19px 15px 15px;
                          font-size: 16px;
                          line-height: 21px;
                          border: 0;
                          height: 100%;
                          border-bottom: solid 1px rgba(0, 0, 0, 0.102);
                          border-color: rgba(0, 0, 0, 0.102) !important;
                          transition: none !important;
                          white-space: pre;
                          @include common-flex-jc;
                          &:hover {
                            opacity: 1 !important;
                          }
                          &:focus {
                            box-shadow: none;
                          }
                          &:focus-visible {
                            outline: none;
                          }
                          @include md-tablet {
                              padding: 16px 12px 12px;
                              font-size: 14px;
                              line-height: 18px;
                          }
                          &.has-error {
                              background-color: #ffeded;
                              color: #ff4949;
                          }
                          &.active {
                              background-color: #fff;
                              font-weight: 600;
                              border: solid 1px rgba(0, 0, 0, 0.102) !important;
                              border-color: rgba(0, 0, 0, 0.102) !important;
                              border-top-left-radius: 10px;
                              border-top-right-radius: 10px;
                              border-bottom: 0 !important;
                              &.has-error {
                                  background-color: #ffe3e3;
                                  color: #ff4949;
                              }
                          }
                      }
                  }
              }
              .tab {
                  &-content {
                      border: solid 1px rgba(0, 0, 0, 0.102);
                      border-top: 0;
                      border-bottom-left-radius: 10px;
                      border-bottom-right-radius: 10px;
                  }
                  &-pane {
                      padding: 24px 27px 27px 27px;
                      @include md-tablet {
                          padding: 15px 17px 17px 17px;
                      }
                      .form-group {
                        margin-bottom: 24px;
                        &:last-child {
                          margin-bottom: 0;
                        }
                      }
                      .form-label {
                          font-size: 18px;
                          line-height: 24px;
                          margin-bottom: 8px;
                          font-weight: 600;
                          color: #000;
                          @include md-tablet {
                              font-size: 14px;
                              line-height: 20px;
                              margin-bottom: 6px;
                          }
                      }
                      .preview-image {
                        width: 70px;
                        height: 70px;
                        overflow: hidden;
                        border-radius: 5px;
                        cursor: pointer;
                        img {
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                        }
                        &-outer {
                          @include common-flex;
                          flex-wrap: wrap;
                          gap: 8px;
                        }
                      }
                  }
              }
          }
          .action-wrapper {
              @include common-flex-jc;
              gap: 16px;
              margin-top: 27px;
              @include md-tablet {
                  gap: 12px;
                  margin-top: 20px;
              }
              button {
                  width: auto;
                  min-width: 174px;
                  @include md-tablet {
                      min-width: 132px;
                  }
              }
          }
      }
    }
    &-footer {
    border: 0;
    padding: 25px 36px 32px;
    @include common-flex-jc;
    .action-wrapper {
      margin: 0;
      button {
        width: fit-content;
        border: solid 1px rgb(68, 68, 68, 0.25);
        background-color: transparent;
        min-width: 205px;
        height: 38px;
        padding: 0;
        font-size: 16px;
        line-height: 22px;
        color: #444444;
        border-radius: 5px;
        @include common-flex-jc;
        &:focus {
          box-shadow: none;
        }
        &:focus-visible {
          outline: none;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
    }
  }
}

.job-description-invoice-modal {
  .modal-header {
    padding: 68px 35px 0 35px !important;
  }
  .modal-body {
    padding: 19px 35px 16px 35px !important;
    .job-desc-data {
      &.shadow-top {
        .job-desc-data-inner {
          &::before {
            opacity: 1;
          }
        }
      }
      &.shadow-bottom {
        .job-desc-data-inner {
          &::after {
            opacity: 1;
          }
        }
      }
    }
    .job-desc-data-inner {
      padding-top: 30px !important;
      padding-bottom: 10px;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: calc(100% - 16px);
        height: 43px;
        background: linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 64%, rgba(255, 255, 255, 0.5) 100%);
        opacity: 0;
        z-index: 2;
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: calc(100% - 16px);
        height: 89px;
        background: linear-gradient(360deg, #FFFFFF 0%, #FFFFFF 64%, rgba(255, 255, 255, 0.5) 100%);
        opacity: 0;
      }
      &.has-scroll {
        &::after {
          opacity: 1;
        }
      }
      ul {
        float: unset !important;
        li {
          margin-bottom: 11px !important;
        }
      }
    }
    .job-description-title {
      font-size: 16px;
      line-height: 32px;
      color: #101828;
      font-weight: 400;
      margin-bottom: 6px;
      span {
        font-weight: 600;
      }
    }
    .button-outer-wrap {
      margin-top: 0 !important;
      .btn-secondary {
        justify-content: center;
        min-width: 150px;
      }
    }
  }
}
