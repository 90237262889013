@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

body {
    font-family: 'Roboto', sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: $text-color;
}

.header3{
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
}

global-datawrap{

  h2.underlined{
    font-size:22px;
    font-weight:400;
    color:#283A5D;
    padding-top:25px;
    margin-bottom: 0px;
    padding-bottom: 25px;

    &:after{
      content:'';
      display:block;
      width:90px;
      height:4px;
      background-image: linear-gradient(270deg, #55ECB3 0%, #48C898 100%);
      border-radius: 5px;
      margin-top:15px;
      margin-bottom: 10px;
    }

  }

}
