.data-card {
  @include position-relative;
  background-color: $white-color;
  border: 1px solid $dark-grey20;
  box-shadow: 0px 30px 30px -10px rgba(40, 58, 93, 0.05);
  padding: 24px 28px;
  margin-bottom: 20px;
  border-radius: 15px;
  // overflow: hidden;
  &-open-jobs {
    @media (max-width: 991.98px) {
      box-shadow: none;
      border: none;
      background-color: transparent;
    }
  }
  &--header {
    @include common-flex-jsb;
    h3 {
      font-weight: 500;
      font-size: 17px;
      line-height: 1.5;
      margin: 0;
    }
    .header-filter {
      background-color: $light-grey;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      padding: 0 13px;
      border-radius: 10px;
      @include common-flex;
      span {
        margin-right: 12px;
      }
      .form-control {
        padding: 0;
        width: 85px;
      }
    }
    &.with-border {
      padding-bottom: 22px;
      border-bottom: solid 1px $light-grey-50;
      height: 61px !important;
    }
  }
  .sm-card {
    padding: 24px 20px 20px 20px;
  }
  &--estimate {
    display: flex;
    flex-direction: column;
  }
}
.custom-data-card{
  // overflow: hidden;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.reach-out-tenant{
  border-radius: 6px;
  background-color: #f7eee1;
  font-size: 12px;
  color: #ff8300;
  padding: 10px 12px;
  line-height: 1.1;
  margin-bottom: 8px;
  span{
    font-weight: bold;
  }
}
.ask-question{
  @include position-relative;
  padding: 0 12px 12px 12px;
  a, button{
    background: rgba(19, 118, 251, 0.1);
    border-style: none;
    border-radius: 7px;
    color: #1376FB;
    font-size: 13px;
    @include position-relative;
    text-align: center;
    padding: 5px 0;
  }
}
