$primary-color: #3dbb8c;
$white-color: #ffffff;

$icon-bg: #48c898;
$text-color: #283a5d;
$blue-color: #3e7cff;
$red-color: #ff5d5d;
$green-color: #2a946d;
$orange-color: #ff8e00;
$light-grey: #f4f6fa;
$light-grey10: #d4d8de;
$light-grey15: #dadee6;
$light-grey20: #e5edfa;
$light-grey30: #e0e7f4;
$light-grey40: #e7effd;
$light-grey-50: #e3ecf5;
$light-grey-60: #f5f8fe;
$light-grey-70: #6a7892;

$dark-grey: #242425;
$dark-grey10: #7e899e;
$dark-grey20: #eaeff4;
$dark-grey30: #697ea7;
$dark-grey40: #d7e1f4;

$graph-brown: #5a5a5a;
$graph-blue: #3e7cff;
$graph-cyan: #c58fff;
$graph-green: #48c898;
$graph-orange: #ff8e00;

$red-color10: #fdeaea;

$co-ordinate: #eee;
$waiting: #3e7cff;
$scheduled: #c58fff;
$work-in-progress: #48c898;
$paused: #ff8e00;
