.expanded-row--data{
  @include position-relative;
  padding: 15px 0;
}

.resident-problem-icon {
  margin-left: 6px;
  @include common-flex-jc;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 12px;
  line-height: 14px;
  flex-shrink: 0;
  &.resident-problem--partially {
    border: solid 1px #FF8E00;
    background-color: #FFF2E8;
    color: #FF8E00;
  }
  &.resident-problem--yes {
    border: solid 1px #EA1C1C;
    background-color: #FFEEEE;
    color: #EA1C1C;
  }
}

.global-table{
  tbody{
    tr{
      td{
        .services{
          display: flex;
          align-items: center;
          img{
            margin-right: 6px;
          }
          &.make_ready{
            svg{
              margin-right: 8px;
            }
            img{
              margin-right: 8px;
            }
          }
          .new-quote-indication {
            width: 14px;
            height: 14px;
            border: solid 2px #fff;
            border-radius: 50%;
            background: linear-gradient(270deg, #FF8A8A 0%, #FF5D5D 100%);
            box-shadow: 0px 0px 5px 0px rgba(255, 93, 93, 0.4968);
            margin-left: 4px;
          }
        }
      }
    }
  }
}
.data-card{
  &.closed-jobs{
    padding: 0;
    min-height: 364px;
    height:96%;


    @media (max-width: 991.98px) {
      height:auto;
    }
    .service-notes{
      padding: 20px;
      overflow: hidden;
      word-break: break-word;
      @media (max-width:575.98px) {
        padding: 15px;
      }
      .job-description{
        padding: 0;
      }
    }
    .job-description-outer {
      position: relative;
      display: flex;
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 63px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 100%);
        opacity: 0;
      }
      &.has-scroll {
        &::after {
          opacity: 1;
        }
      }
      .job-description {
        overflow: hidden;
      }
    }
    .job-description{
      @include position-relative;
      padding: 20px;
      margin: 0;
      font-weight: normal;
      font-size: 13px;
      line-height: 15px;
      max-height: 243px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #F4F6FA;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #d8e1f2;
        border-radius: 6px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: darken($color: #d8e1f2, $amount: 20);
      }
      .job-description-title {
        font-size: 12px;
        line-height: 24px;
        color: #101828;
        font-weight: 400;
        margin-bottom: 6px;
        span {
          font-weight: 600;
        }
      }
      li{
        list-style: none;
        margin-bottom: 12px;
        line-height:1.6em;
        position: relative;
        padding-left: 21px;
        white-space: break-spaces;
        word-break: break-word;
        &:last-of-type{
          margin-bottom: 0;
        }
        &:after{
          @include position-absolute-fl;
          content: '';
          width: 8px;
          height: 8px;
          border-radius: 2px;
          background-color: #B8D5FD;
          top: 4px;
          left: 0px;
        }
        .paused{
          font-weight: bold;
          color: $orange-color;
          margin: 0 5px;
        }
        .completed{
          font-weight: bold;
          color: $work-in-progress;
          margin: 0 5px;
        }
      }
      &--make-ready{
        li{
          font-size: 14px;
          strong{
            font-weight: 600;
          }
          &::after{
            top: 7px;
          }
        }
      }
    }
    .view-description{
      @include position-relative;
      @include common-flex-jc;
      bottom: 0px;
      min-height:100px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.90) 0%, #FFFFFF 100%);
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      @media( max-width: 1199.98px){
       left: 5%;
        width: 90%;
      }
      .btn-secondary{
        @media( max-width: 1199.98px){
          padding: 10px 26px;
        }
      }
    }
    .resident-problem-row {
      td {
        padding: 16px 20px !important;
        &::after {
          all: unset;
        }
      }
    }
    .resident-problem-alert {
      padding: 12px;
      border: 1px solid;
      border-radius: 8px;
      gap: 8px;
      display: flex;
      align-items: flex-start;
      &-content {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        p {
          font-weight: 400;
          font-size: 13px;
          margin-bottom: 0; 
          max-height: 152px;
          overflow-y: auto;
          padding-right: 6px;
          white-space: pre-wrap;
          word-break: break-word;
        }
        .resident-problem-alert-title {
          font-weight: 500;
        }
      }
      &-yes {
        border-color: #F6D8D8;
        background-color: #FFF6F6;
        span {
          color: #EA1C1C;
        }
      }
      &-partially {
        border-color: #FFC8A6;
        background-color: #FFF8F3;
        span {
          color: #FF6200;
        }
      }
      .resident-problem-icon {
        margin-left: 0;
      }
    }
  }
}
.map-section{
  @include position-relative;
  background: $white-color;
  border: 1px solid #E3ECF5;
  box-shadow: 0px 20px 20px -10px rgba(40, 58, 93, 0.05);
  border-radius: 10px;
  padding: 15px;
  @media( max-width:991.98px){
    padding: 3px 0;
  }
  &-data{
    @include position-relative;
    min-height: 330px;
    @include common-flex-jc;
    background-color: #F0F1F3;
  }
}
.image-outer{
  padding: 12px;
  @include common-flex;
  flex-wrap: wrap;
  a, button{
    margin: 3px;
    border: solid 2px transparent;
    border-radius: 10px;
    overflow: hidden;
    img{

      object-fit: cover;
    }
    &:hover{
      border-color: $graph-green;
      background: linear-gradient(0deg, rgba(71, 200, 152, 0.7), rgba(71, 200, 152, 0.7));
    }
  }
}

.accordion-xs{
  @media( max-width:991.98px ){
    min-height: auto !important;
    margin-bottom: 12px !important;
    border-radius: 10px !important;
  }
  h3{
    @media( max-width:991.98px ){
      @include common-flex;
      font-size: 16px;
    }
  }
  .accordion-handle-xs{
    display: none;
    @media( max-width:991.98px ){
      display: block;
      margin-right: 5px;

    }
  }
  .with-border{
    @media( max-width:991.98px ){
      border-bottom: none !important;
      padding: 7px 15px 7px 5px;

    }
  }
  .data-card-data{
    @media( max-width:991.98px ){
      display: none !important;

    }
  }

}
.job-location-heading{
  display: none;
  @media( max-width:991.98px){
    display: flex;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin: 0;
    padding: 3px 7px;
  }
  @media( max-width:991.98px ){
    align-items: center;
    font-size: 16px;
  }
}
.map-section-data{
  @media( max-width:991.98px ){
    display: none !important;
  }

}
.accordion-handle-xs{
  @media( max-width:991.98px ){
    margin-right: 0;
    font-size: 16px;
  }
}

.export-jobs-form{
  @include position-relative;
  margin-top: 36px;
  .form-group{
    margin-bottom: 20px;
    margin-top: 10px;
    @media(max-width:565.98px){
      margin-bottom: 10px;
    }
    label{
      display: flex;
      font-family: Roboto;
      font-weight: normal;
      font-size: 20px;
      white-space: nowrap;
    }
    .custom-control{
      margin-right: 11px;
    }
    .custom-radio .custom-control-label::before {
      border-radius: 50% !important;
    }
    .custom-control-label:after{
      width: 10px;
    height: 10px;
    top: 4.2px;
    left: -19.5px;
    background-image: none;
    background: #fff;
    border-radius: 50%;
    }
  }
  .date-range-outer{
    @include position-relative;
    margin-top: -9px;
    @media( max-width:565.98px){
      margin-left: 0px;
      margin-top: 20px;
    }
  }
  .close-job-btn-outer{
    @include position-relative;
    @include common-flex-jc;
    margin-top: 40px;
    margin-bottom: 10px;
    @media( max-width:565.98px){
      display: block;
      margin-top:10px;
    }

    .btn-secondary{
      padding: 9px 51px;
      margin-right: 15px;
      @media(max-width:565.98px){
        width: 100%;
        display: block;
        margin-right: 0px;
        margin-bottom:15px;
      }
    }
    .btn-primary{
      padding: 10px 66px;
      @media(max-width:565.98px){
        width: 100%;
        display: block;
      }
    }

  }
  .dark{

    .form-control{
      background: #F4F6FA url('../../assets/images/global/dropdown.svg') no-repeat  right 10px center;
      @media( max-width:767.98px){
       margin-bottom: 15px;
      }
    }
    .date-picker{
      background-color: $white-color;
      box-shadow: 0px 0px 40px rgba(40, 58, 93, 0.2);
      border-radius: 10px;
      overflow: hidden;
      .rdrMonthAndYearWrapper{
        padding-top: 0px;
      }
    }
  }
}

.review-active{
  path{
    fill: $primary-color;
  }
}

.closed-button-review-col {
  display: flex;
  justify-content: center;
}

.no-review{
  @include position-relative-fl;
  @include common-flex-jc;
  width: 32px;
  height: 32px;
  line-height: 1;
  border-radius: 50%;
  background-color:#ECEDF0;
  cursor: default;
  border: 0;
  &:hover{
    opacity: .8;
  }
}
.review-added{
  @include position-relative-fl;
  @include common-flex-jc;
  height: 32px;
  min-width: 92px;
  background: linear-gradient(270deg, #55ECB3 0%, #48C898 100%);
  border-radius: 25px;
  font-weight: 500;
font-size: 15px;
line-height: 18px;
color: #fff !important;
border: 0;
  i{
    margin-right: 6px;
  }
}
.export-excel{
  h4{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
  }
  .invoice-status-outer{
    .form-check-label{
      @include position-relative;
    }
    .form-check-input{
      position: relative;
      width: 20px;
      height: 20px;
      right: 5px;
      top:6px;
      &:checked[type=checkbox]{
        background-color: $primary-color !important;
        border-color: $primary-color;
        appearance: inherit;
        border-radius: 4px;
        background-image: url(../../assets/images/checkbox/tick.svg);
        background-repeat: no-repeat;
        background-position: 4px;
      }
    }
  }
}

.job-appliance-table {
  padding: 0 30px;
  .global-table{
    thead{
      background: transparent;
      border: none;
      tr{
        th{
          padding: 12px 15px;
          text-transform: capitalize;
          color: #000;
          font-weight: 500;
        }
      }
    }
    tbody {
      tr{
        &:nth-child(odd){
          background-color: #F7FAFD;
        }
        td {
          vertical-align: middle;
          padding: 6px 15px !important;
          border-top: none !important;
                 }
      }
      
    }
  }
 
}

.appliance-img{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  &--item{
    width: 40px;
    height: 40px;
    border-radius: 6px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
      cursor: pointer;
      object-fit: cover;
    }
    &.view-more{
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background-color: $light-grey40;
    }
  }
}

.no-data-found {
  text-align: center;
  width: 100%;
  padding-top: 20px;
  font-size: 12px;
  color: #a5a5a5;
}

.hvac-report-table {
  padding: 15px 20px 25px;
  table {
    margin-bottom: 0;
    thead {
      border: 0;
      tr {
        th {
          padding: 0 10px 6px;
          background-color: #fff;
          font-size: 11px;
          line-height: 14px;
          color: #666666;
          text-transform: capitalize;
          &:first-child {
            width: 38%;
          }
          &:nth-child(2) {
            width: 26%;
          }
          &:last-child {
            width: 36%;
            text-align: end;
            padding-right: 18px;
          }
          &.text-start {
            text-align: start !important;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 12px;
          line-height: 16px;
          color: #000;
          border: 0;
          padding: 6px 9px !important;
          background-color: rgba(0, 0, 0, 0.02);
          border: solid 1px rgba(0, 0, 0, 0.1);
          &:first-child {
            border-right: 0;
          }
          &:last-child {
            border-left: 0;
          }
          &:nth-child(2) {
            border-left: 0;
            border-right: 0;
          }
          .view-btn {
            background-color: #1D66FF;
            color: #fff;
            padding: 5px 15px;
            border: 0;
            border-radius: 3px;
            font-size: 11px;
            line-height: 15px;
            display: block;
            margin-left: auto;
            &:focus-visible {
              outline: none;
            }
          }
        }
      }
    }
  }
}