.notifications-xl{
    @include position-relative;
    @include common-flex;
    align-items: flex-start;
    border-radius: 15px;
    padding: 31px 37px;
    margin: 0 0 20px 0;
    background-color: $light-grey20;
    &--icon{
        width: 59px;
        margin-right: 21px;
        @media( max-width:565.97px){
            display: none;
        }
    }
    &--data{
        @media( max-width: 991.98px){
            width: 80%;
        }
        @media( max-width: 565.98px){
            width: 90%;
        }
        h2{
            font-weight: 500;
            font-size: 24px;
            line-height: 25px;
            @media( max-width:565.97px){
                font-size: 20px;
                line-height: 27px;
            }
        }
        p{
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            a{
                color: $blue-color;
            }
        }
    }
    &--close{
        @include position-absolute-fl;
        top: 20px;
        right: 21px;
        width: 38px;
        height: 38px;
        background-color: $white-color;
        border-radius: 10px;
        @include common-flex-jc;
        @media( max-width:565.97px){
            top: 32px;
        }
        &:hover{
            opacity: .5;
        }
    }
}
