.custom-control-label{
    transition: all 0.3s cubic-bezier(0.83, 0, 0.17, 1);
    &::before{
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 7px !important;
        border-color: $light-grey10;
        top:-1px;
        cursor: pointer;
    }
    &:after{
        content:'';
        width:20px;
        height: 15px;
        top: 0px;
        left: -20px;

    }
}
.custom-control-input:checked ~ .custom-control-label::before{
    background-color: $primary-color;
    border-color: $primary-color;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background: url(../../assets/images/checkbox/tick.svg) no-repeat center;
    background-size: cover;
    transition: all 0.4s cubic-bezier(0.83, 0, 0.17, 1);
    transition-delay: 50ms;
    width: 20px;
    height: 15px;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: none;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before{
    background-color:$primary-color;
    border-color: $primary-color;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before{
    border-color: lighten($primary-color, 30%);
}


/*Custom Switch */

.custom-switch{
  padding-left: 0 !important;
  position: relative;
  z-index: 0;
  .custom-control-label{
    &::before{
      width: 42px;
      height:22px;
      top:0;
      background: #E6EDF8;
      border: 1px solid #DEE2EB;
      border-radius: 8px;
    }
    &::after{
      width: 16px;
      height: 16px;
      background: linear-gradient(270deg, #C0CDE3 3.63%, #98A7C0 98.15%);
      border-radius: 6px;
      top:3px;
      transition: all 0.5s cubic-bezier(0.83, 0, 0.17, 1);
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background: linear-gradient(270deg, #55ECB3 0%, #48C898 100%);
    box-shadow: 0px 0px 20px #48C898;
    transform: translateX(22px);
    transition: all 0.5s cubic-bezier(0.83, 0, 0.17, 1);
  }
  .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #E4F7F0;
    border-color:#BBEBDA;
  }

}


.lula-switch{
  .custom-control-label{
    &::before{
      width: 42px;
      height:22px;
      top:0;
      background: #FFF1F1;
      border: 1px solid #DEE2EB;
      border-radius: 8px;
    }
    &::after{
      width: 16px;
      height: 16px;
      background: linear-gradient(270deg, #FF8A8A 0%, #FF5D5D 100%);
      // box-shadow: 0px 0px 20px #FF5D5D;
      border-radius: 6px;
      top:3px;
      transition: all 0.5s cubic-bezier(0.83, 0, 0.17, 1);
    }
  }
  .custom-control-input:checked ~ .custom-control-label::after {
    background: linear-gradient(270deg, #55ECB3 0%, #48C898 100%);
    box-shadow: 0px 0px 20px #48C898;
    transform: translateX(22px);
    transition: all 0.5s cubic-bezier(0.83, 0, 0.17, 1);
  }
  .custom-control-input:checked ~ .custom-control-label::before{
    background-color: #E4F7F0;
    border-color:#BBEBDA;
  }

}
