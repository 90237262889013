.global-filters{
  @include position-relative;
  @include common-flex;
  justify-content: flex-start;
  padding: 20px;
  background-color: $white-color;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #EAEFF4;
  border-bottom: 0;
  .form-control{
    &::-webkit-input-placeholder { /* Edge */
      color: #A6A6A6;
    }
    
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #A6A6A6;
    }
    
    &::placeholder {
      color: #A6A6A6;
    }
  }
  &-open-jobs{
    .form-control{
        width:25%;
        margin: 0 14px 0 0;
        &-resize {
          width: 12%;
          margin-right: 15px;
        }
        &-closed-jobs {
          width: 20%;
          margin-right: 15px;
        }
        @media( max-width: 1199.98px){
          width: 23%;
        }
        
    }
    .global-select{
        width: 20%;
        margin: 0 14px 0 0;
        &-resize{
          width: 12%;
          margin-right: 15px;
          @media( max-width: 1549.98px){
            width: 15%;
          }
        }
        
        @media( max-width: 1199.98px){
          width: 23%;
        }
    }
    .global-date-picker{
        width: 16%;
        margin: 0 14px 0 0;
        &-resize{
          width: 12%;
          margin-right: 15px;
        }
        
        @media( max-width: 1199.98px){
          width: 23%;
        }
        .dark{
          .form-control{
            background: transparent !important;
            padding-left: 0;
          }
        }
    }
    .global-date-picker.disabled {
      cursor: not-allowed;
      background-color: #f9f9f9;
      .dark {
        .form-control {
          cursor: not-allowed;
          color: #dcdddd;
        }
      }
      svg {
        path {
          fill: #dcdddd;
        }
      }
    }
    @media(max-width: 991.98px) {
      display: none;
     }
    .global-switch{
      display: flex;
      align-items: center;
      white-space: nowrap;
      width: 14%;
      justify-content: center;
      margin-left: 1.25rem !important;
      @media(max-width:1349.98px){
        margin-left: 2.25rem !important;
      }
      .custom-control-label {
        padding: 0px 10px;
        color: #283A5D;
        font-size: 13px;
        line-height: 1.7;
      }
    }
  }
  &-open-jobs-mobile{
    padding: 0;
    display: block;
    .form-control{
      width: 100%;
      margin: 0 0 15px 0;
    }
    .global-select{
      width: 100%;
      margin: 0 0 15px 0;

    }
    .global-date-picker{
      position: relative;
      width: 100%;
      margin: 0 0 15px 0;
      .dark{
        .form-control{
          margin: 0;
        }
      }
    }
    .btn-clear{
      @include common-flex-jc;
      margin-top: 25px;
      width: 100%;
    }
  }
  .btn-secondary{
    padding: 12.5px 18px;
    line-height: 1;

    &:hover{
      // opacity:0.7;
    }
  }
}
.filter-btn-outer{
display: none;
@media( max-width: 991.98px){
  @include common-flex;
  @include position-relative;

  .btn-secondary{
    padding: 9px 20px;
    width: 50%;
    margin-right: 15px !important;
    margin-top: 15px;
    text-align: center;
  }
  .btn-primary{
    padding: 10px 20px;
    width: 50%;
    margin-top: 15px;
    text-align: center;
  }
}
}
@media( max-width: 767.98px){
  .btn-secondary{
    display: flex;
    justify-content: center;
  }
}

.filter-mobile{
  .form-control{
    margin-bottom: 15px;
  }
  .global-select{
    margin-bottom: 15px;
  }
  .global-date-picker{
    margin-bottom: 30px;
    .dark{
      .form-control{
        margin: 0 !important;
        background: transparent !important;
      }
    }
  }
  .global-switch{
    margin-bottom: 10px;
    .custom-switch  {
      position: unset;
      padding-left: 2.25rem !important;
    }
    .custom-control-label {
      padding: 0 10px !important;
    }
  }
  .btn-clear{
    width: 100%;
    @include common-flex-jc;
  }
  .filter-data-inner{
    @include position-relative;
    margin-top: 25px;
  }
  .resident-caused-checkbox {
    margin-bottom: 30px;
  }
}

.resident-caused-checkbox {
  @include common-flex;
  margin-right: 10px;
  .custom-control-input {
    &:checked {
      & + {
        .custom-control-label {
          color: #283A5D;
        }
      }
    }
  }
  .custom-control-label {
    font-size: 14px;
    line-height: 16px;
    color: #64728D;
    transition: none !important;
    &::before {
      width: 18px;
      height: 18px;
      border-radius: 4px !important;
      border-color: #64728D;
    }
    &::after {
      left: -24px;
      background-size: 12px !important;
      background-position: center;
    }
  }
}