
@mixin position-absolute {
    position: absolute;
    width: 100%;
    float: left;
  }
  @mixin position-absolute-fl {
    position: absolute;
    float: left;
  }
  @mixin position-relative {
    position: relative;
    width: 100%;
    float: left;
  }
  
  @mixin position-relative-fl {
    position: relative;
    float: left;
  }
  
  @mixin position-relative-fr {
    position: relative;
    float: right;
  }
  
  @mixin common-flex {
    display: flex;
    align-items: center;
  }
  
  @mixin common-flex-jc {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @mixin common-flex-jc-as {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  
  @mixin common-flex-jsb {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  

  @mixin ultra-hd {
    @media (max-width: 1699.98px) {
        @content;
    }
}

@mixin xl-desktop {
    @media (max-width: 1399.98px) {
        @content;
    }
}

@mixin lg-desktop {
    @media (max-width: 1299.98px) {
        @content;
    }
}

@mixin md-desktop {
    @media (max-width: 1199.98px) {
        @content;
    }
}

@mixin md-tablet {
    @media (max-width: 991.98px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 767.98px) {
        @content;
    }
}

@mixin min-mobile {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin sm-mobile {
    @media (max-width: 575.98px) {
        @content;
    }
}

@mixin xs-mobile {
    @media (max-width: 320px) {
        @content;
    }
}