.sidebar-logo-outer {
  @include position-relative;
  // height: 17vh;
  padding-top: 40px;
  padding-left: 30px;
  height: 140px;

  @media (max-width: 991.98px) {
    height: auto;
    padding: 0px;
  }

  h5 {
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 1.07692px;
    margin: 12px 0 15px 0;
  }

  svg,
  img {
    max-width: 90px;
    height: auto;
  }
}

.sidebar-top-section {
  @include position-relative;
  width: 100%;
  height: 100%;
  padding: 0px !important;

  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // flex-grow: 1;
  // flex: 1;
  @media (max-width: 991.98px) {
    padding: 0 !important;
  }
}

.sidebar-bottom-section {
  @include position-absolute;
  height: auto;
  padding: 20px 0 25px 30px;
  // height: 10vh;
  bottom: 0px;
  left: 0px;
  top: auto;
  right: auto;
  opacity: 0.5;

  .copoyright {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 7px;
  }
}

.sidebar-wrapper {
  max-height: calc(100vh - 90px);
  width: 260px;
  max-width: 280px;
  // display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;

  @media (max-width: 991.98px) {
    width: 295px;
    max-width: 295px;
    max-height: 100vh;
  }
}

.global-sidebar {
  width: 260px;
  height: 100vh;
  // overflow: auto;
  padding: 0;
  position: fixed;
  z-index: 100;
  max-height: 100%;

  @media (max-width: 991.98px) {
    width: 295px;
    right: -295px;
    background: $white-color;
    box-shadow: 0px 0px 100px rgba(40, 58, 93, 0.295291);
    // display: none;
    transition: all 0.3s cubic-bezier(0.85, 0, 0.15, 1);
  }
}

.global-sidebar.open {
  @media (max-width: 991.98px) {
    // display: block;
    right: 0px;
  }
}

.admin-sidebar {
  // @include position-relative;
  list-style: none;
  padding: 0 0 1px 25px;
  height: calc(100% - 140px);
  // max-height: 70vh;
  // height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  direction: rtl;
  margin: 0px;
  // height: 1px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #dfe2e7;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #9ba2b1;
  }

  @media (max-width: 991.98px) {
    // height: auto;
    // overflow: unset;
    height: calc(100% - 80px);
    padding: 0 0 1px 24px;
    padding-bottom: 30px;
  }

  // &::-webkit-scrollbar {
  //   width: 5px;
  // }

  // /* Track */
  // &::-webkit-scrollbar-track {
  //   background: $grey10;
  // }

  // /* Handle */
  // &::-webkit-scrollbar-thumb {
  //   background: $primary-color;
  // }

  // /* Handle on hover */
  // &::-webkit-scrollbar-thumb:hover {
  //   background: $primary-color;
  // }
}

.sidebar__list {
  position: relative;
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  direction: ltr;

  &.comingsoon {
    opacity: 0.6;
  }
}

.sidebar__list--text {
  font-size: 14px;
  color: #53617d !important;
  cursor: pointer;
  width: 100%;
  @include common-flex;
  transition: 0.2s all;
  padding-right: 6px;

  i {
    height: 36px;
    width: 36px;
    border-radius: 10px;
    @include common-flex-jc;
    margin-right: 15px;
    flex-shrink: 0;
  }

  &:hover {
    text-decoration: none;

    i {
      background-color: $light-grey40;
    }
  }

  .custom-tag {
    margin-left: 8px;
  }

  &.active {
    i {
      &.catalog-icon {
        svg {
          path {
            fill: none !important;
            stroke: $white-color;
          }
        }
      }
    }
  }
}

body .sidebar__list .active {
  font-weight: 500;
  color: #283A5D !important;

  i {
    background: linear-gradient(270deg, #55ecb3 0%, #48c898 100%);
    box-shadow: 0px 10px 10px rgba(72, 200, 152, 0.25);

    svg {
      
      path {
        fill: $white-color;
        &.stroke-color{
          stroke: $white-color;
        }
      }
      rect {
        fill: $white-color;
        &.stroke-color{
          stroke: $white-color;
        }
      }
      .quote-stroke{
        stroke: $white-color;
      }
    }
  }
}

.coming-soon {
  position: relative;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #283a5d;
  opacity: 0.6;
  margin: 32px 0 16px 0;

  &:after {
    @include position-absolute-fl;
    content: "";
    width: 38px;
    height: 2px;
    background-color: #7E899E;
    opacity: 0.3;
    top: 5px;
    left: -45px;
  }
}

.sidebar .sidebar__sub .active1 {
  font-weight: 600;
  background-color: transparent !important;
}

.sidebar__sub-list--text:hover,
.sidebar__sub-list--text:focus,
.sidebar__sub-list--text:active,
.sidebar .sidebar__sub .active1 {
  text-decoration: none;
  color: $primary-color !important;
}

.sidebar__footer {
  text-align: center;
  @include common-flex-jc;
  margin-top: auto;
  margin-bottom: 15px;
}

.sidebar__footer-text {
  font-size: 15px;
  color: pink;
  padding-top: 10px;
  margin-bottom: 0;
  @include common-flex;
}

.sidebar__footer-logo {
  padding-right: 8px;

  img {
    position: relative;
    top: 4px;
  }
}

.sidebar-wrapper:last-child {
  padding: 0;
}

.sidebar__sub-menu {
  padding: 0 25px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  list-style: none;

  .sidebar__sub-list {
    margin: 6px 0;
    padding-left: 8px;
  }

  .sidebar__sub-list--text {
    font-size: 12px;
    text-transform: uppercase;
    color: pink !important;
    cursor: pointer;
    width: 100%;
    padding: 11px 23px 11px 24px;
    transition: 0.4s all;

    &:hover {
      color: $white-color;
    }

    i {
      margin-right: 15px;
    }
  }
}

.sidebar-footer {
  @include position-relative-fl;
  width: 90%;
  left: 5%;
  text-align: center;
  padding: 15px 0 15px 0;
  font-size: 15px;
  color: pink;
  border-top: solid 1px #555;

  i {
    margin: 0 5px;
  }
}

.sidebar-top-section-mobile {
  display: none;

  @media (max-width: 991.98px) {
    @include position-relative;
    display: flex;
    justify-content: flex-end;
    padding: 15px 15px 15px 15px;
  }
}

.mobile-notification {
  @include position-relative-fl;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: $light-grey20;
  border: solid 1px $light-grey20;
  @include common-flex-jc;

  &:hover {
    background-color: $white-color;
    border-color: $light-grey30;
  }

  &.unread {
    &:after {
      @include position-absolute-fl;
      content: "";
      top: 12px;
      right: 12px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      border: solid 1px $white-color;
      background: linear-gradient(270deg, #ff8a8a 0%, #ff5d5d 100%);
    }
  }
}

.item-count {
  @include position-absolute-fl;
  inset: -8px 0 0 17px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: solid 2px $white-color;
  background: linear-gradient(270deg, #FF8A8A 0%, #FF5D5D 100%);
  box-shadow: 0px 0px 5px rgba(255, 93, 93, 0.496755);
  font-size: 11px;
  color: $white-color;
  line-height: 21px;
  text-align: center;
  font-style: normal;
}

.mobile-settings {
  @include position-relative-fl;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: $light-grey20;
  border: solid 1px $light-grey20;
  @include common-flex-jc;
  margin-left: 15px;

  &:hover {
    background-color: $white-color;
    border-color: $light-grey30;
  }
}

.mobile-navbar-toggler {
  background: linear-gradient(270deg, #55ecb3 0%, #48c898 100%);

  .navbar-toggler-icon {
    background-image: url(../../assets/images/global/hamburger-menu--white.svg) !important;
    background-size: 80%;
  }
}

.sidebar-asset-management {
  display: none;

  @media (max-width: 991.98px) {
    @include position-relative;
    @include common-flex;
    background-color: $light-grey-60;
    padding: 10px 20px;
    display: none;

    h5 {
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      margin: 0 0 0 15px;
      color: $text-color;
    }
  }
}

.cla {
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
}
