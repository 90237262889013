.auth-form{
    @include position-relative;
    .form-group{
        @include position-relative;
        @include common-flex;
        background: $light-grey;
        border-radius: 10px;
        height: 38px;
        padding: 0 20px;
        .forgot-password{
            font-family: "Open Sans", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            color: $blue-color;
        }
    }
    .form-control{
        background-color: transparent;
        border: none;
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        opacity: 1.0;
        padding-left: 12px;
        &:focus{
            box-shadow: none;
        }
    }
    .keep-me{
        @include position-relative;
        @include common-flex;
        font-family: "Open Sans", sans-serif;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 10px;
        label{
            margin: 4px 0 0 20px;
            cursor: pointer;
        }
        input{
            margin-left: 0;
            cursor: pointer;
        }
    }
    .auth-btn-outer{
        @include position-relative;
        text-align: center;
        &.auth-btn-outer.auth-btn-outer--forgot-password {
            margin: 10px 0 10px 0;
        }
        .auth-wrap__text-link{
            @include position-absolute;
            font-family: "Open Sans", sans-serif;
            font-weight: normal;
            font-size: 12px;
            text-align: center;
            color: $blue-color;
            bottom: -45px;
            left: 0;
        }
        &--forgot-password{
            .btn-primary{
                padding: 10px 31px;
            }
        }
    }
}
.form-group{
    label{
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
    }
}

input:-internal-autofill-selected {
  background: #F4F6FA !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border:none;
  -webkit-text-fill-color:none;
  -webkit-box-shadow: none;
  transition: none;
  background: #F4F6FA !important;
}

.form-control{
    background: #F4F6FA;
    color: #515F7C;
    border-radius: 10px;
    border: none;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    opacity: 1.0;
    padding-left: 12px;
    height: 38px;
    &:focus{
        box-shadow: none;
        background: #F4F6FA;
    }
}

.global-select {
    background: #F4F6FA;
    border-radius: 10px;
    border: none;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    height: 38px;
    .css-yk16xz-control{
        background: none;
        border: none;
        color: #515F7C;
        &:focus{
            box-shadow: none;
        }
    }
    .css-1pahdxg-control{
        background: transparent;
        box-shadow: none;
        border: none;
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }

  .global-date-picker{
    position: relative;
    background-color:$light-grey;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    padding: 0px 10px;
    border-radius: 10px;
    @include common-flex-jsb;
    color: #A6A6A6;
    span{
        margin-right: 12px;
    }
    .dark{
      width: 100%;
      .form-control{
        width: 100% !important;
      }
    }

}

