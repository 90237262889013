.data-card-open-jobs {
  border-radius: 0px 0px 15px 15px;
}
.open-jobs{
    @include position-relative;
    display: flex;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: auto;
    border-top: solid 1px $dark-grey40;
    margin: 0;
    padding: 0;
    height: calc(100vh - 210px);
    @media( max-width:991.98px){
      border-top: none;
      height: auto;
      overflow: unset;
    }
    @media( max-width:991.98px){
      display: block;
    }
    li{
        @include position-relative-fl;
        position: relative;
        width: 20%;
        display: flex;
        flex-shrink: 0;
        flex-direction: column;
        margin: 0;
        list-style: none;
        border-left: 1px solid $dark-grey40;
        @media( max-width:991.98px){
          display: block;
          width: 100%;
          border: 1px solid #E4EBF1;
          border-radius: 10px;
          margin-bottom: 15px;
        }
        &:first-child{
            border-left: 0;
            @media( max-width:991.98px){
              border-left: 1px solid $dark-grey40;
            }
        }
        .jobs-heading{
            @include position-relative;
            @include common-flex;
            min-height: 58px;
            border-bottom: 1px solid $dark-grey40;
            padding: 8px 13px;
            @media( max-width:991.98px){
              border-bottom: none;
              border-radius: 10px;
            }
            &-icon{
                width: 5px;
                height: 40px;
                border-radius: 7px;
                @media( max-width:991.98px){
                  width:11.05px;
                  height:11.05px;
                  border-radius: 50%;
                }
            }
            &-data{
                margin-left: 17px;
                h4{
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: $dark-grey;
                    margin: 0;
                }
                p{
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 16px;
                    color: #242425;
                    margin: 0;
                }
                @media( max-width:991.98px){
                  @include common-flex-jsb;
                  width: 100%;
                  h4{
                    order: 1;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 19px;
                    white-space: nowrap;
                  }
                  p{
                    order: 0;
                    font-weight: normal;
                    font-size: 17px;
                    line-height: 20px;
                  }
                }
                &--inner{
                  @media( max-width:991.98px){
                    @include common-flex-jsb;
                    width: 100%;
                    margin-right: 18px;

                  }

                }
                .accordion-btn{
                  display: none;
                  @media( max-width:991.98px){
                    display: block;
                  }
                }
            }
        }
        .jobs-data-wrap {
           @include position-relative;
            padding: 10px;
            overflow-y: auto;
            float: left;
            @media( max-width:1299.98px){
              padding: 7px;
            }
            @media( max-width:991.98px){
              background-color: $white-color;
              padding: 10px;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
            }

            &::-webkit-scrollbar {
              width: 6px;
            }

            &::-webkit-scrollbar-track {
              background: #F4F6FA;
            }

            &::-webkit-scrollbar-thumb {
              background: #d8e1f2;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: darken($color: #d8e1f2, $amount: 20);
            }
            .jobs-card{
                @include position-relative;
                border: 1px solid $light-grey15;
                border-radius: 10px;
                margin-bottom: 10px;

                @media (max-width: 991.98px) {
                  width: 48%;
                  float: left;
                  margin-bottom: 0px;
                  margin: 1%;
                }

                @media (max-width: 565.98px) {
                  width:100%;
                  margin:0px;
                  margin-bottom: 10px;
                }

                &:last-child{
                  margin-bottom: 0px;
                }

                .view-job-issues {
                  color: #1376FB;
                  cursor: default;
                }

                &-top-section{
                    @include position-relative;
                    border-bottom: solid 1px $light-grey15;
                    padding: 10px 12px;
                    @media( max-width:1299.98px){
                      padding: 8px 6px;
                    }
                    @media (max-width: 565.98px) {
                      padding:15px;
                    }
                    .data-row{
                        @include position-relative;
                        @include common-flex-jsb;
                        margin-top: 7px;
                        @media (max-width: 565.98px) {
                          margin-top:12px;
                        }
                        &:first-child{
                            margin-top: 0px;
                        }
                        .left-data{
                            @include common-flex;
                            p{
                                font-weight: normal;
                                font-size: 12px;
                                line-height: 15px;
                                margin:0 0 0 7px;
                                @media (max-width: 565.98px) {
                                  font-size:15px;
                                }
                                strong{
                                    font-weight: 500;
                                    display:inline-block;
                                }
                                a{
                                    color: $blue-color;
                                  }
                                  button {
                                    color: $blue-color;
                                    border: 0;
                                    padding: 0;
                                    background-color: transparent;
                                    &:focus-visible {
                                      outline: none;
                                    }
                                }
                            }

                            svg{
                              position: relative;
                              top:-1px;
                            }
                        }
                        .right-data{
                            @include common-flex;

                            .job-id{
                                background-color:rgba(62, 124, 255, 0.06);
                                border-radius: 6px;
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 21px;
                                padding: 0 6px;
                                min-height: 19px;
                                color: $blue-color;
                                border: 0;
                                transition: all .4s;
                                // margin-right: 7px;
                                &:hover{
                                  background-color:rgba(62, 124, 255, .6);
                                  color: $white-color;
                                  text-decoration: none;
                                }
                                &:focus-visible {
                                  outline: none;
                                }
                                @media (max-width: 565.98px) {
                                  font-size:11px;
                                }
                            }
                            a{
                              float:right;
                              &:hover{
                                background-color:rgba(62, 124, 255, 0.08);
                                  text-decoration: none;
                              }

                              svg, img{
                                float: right;
                                width: 21px;
                                height: 21px;
                                margin-left: 7px;
                              }
                            }
                        }

                    }
                    .reach-out-tenant {
                      float: left;
                      margin-top: 6px;
                    }
                    .availability-provided{
                      @include position-relative;
                      @include common-flex-jsb;
                      background: #CDDCFF;
                      border-radius: 6px;
                      text-align: center;
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 12px;
                      min-height: 25px;
                      padding: 4px 7px;
                      gap: 6px;
                      text-align: left;
                      span {
                        color: #1376FB;
                        cursor: default;
                      }

                      @media (max-width: 565.98px) {
                        font-size:14px;
                        min-height:28px;
                        line-height: 28px;
                      }
                      &.submitted {
                        background-color: rgba(66, 128, 252, 0.1);
                      }
                      &.scheduled {
                        background-color: rgba(197, 143, 255, 0.1);
                      }
                    }
                    .resident-availability-tooltip{
                      width: fit-content;
                      background: #fff !important;
                      padding: 10px 12px;
                      border: solid 1px #D7E1F4;
                      border-radius: 8px;
                      opacity: 1;
                      h4 {
                        color: #283A5D!important;
                        font-size: 12px;
                        font-weight: 500;
                        margin-bottom: 4px;
                      }
                      .available-slot{
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        margin-bottom: 5px;
                        &:last-child {
                          margin-bottom: 0;
                        }
                        svg {
                          flex-shrink: 0;
                        }
                        p{
                          color: #283A5D;
                          font-size: 10px;
                          margin: 0;
                          display: inline;
                        }
                      }
                    }

                    .job-closed-label-tooltip {
                      width: fit-content;
                      background: #fff !important;
                      padding: 10px 12px;
                      border: solid 1px #D7E1F4;
                      border-radius: 8px;
                      opacity: 1;
                      max-width: 193px;
                      h4 {
                        color: #283A5D!important;
                        font-size: 12px;
                        font-weight: 500;
                        margin-bottom: 4px;
                      }
                      .job-issues-banner {
                        background-color: #ECFAF4;
                        padding: 6px;
                        color: #283A5D;
                        font-size: 10px;
                        padding: 4px 8px;
                        margin-bottom: 4px;
                        border-radius: 4px;
                      }
                      .job-issues {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        margin-bottom: 5px;
                        &:last-child {
                          margin-bottom: 0;
                        }
                        svg {
                          flex-shrink: 0;
                          margin: 0;
                        }
                        p {
                          color: #283A5D;
                          font-size: 10px;
                          margin: 0;
                          display: inline;
                        }
                      }
                    }
                }
                &-middle-section{
                    @include position-relative;
                    padding: 12px 12px;

                    @media( max-width:1299.98px){
                      padding: 8px 6px;
                    }

                    @media (max-width: 565.98px) {
                      padding:15px;
                    }

                    ul{
                        @include position-relative;
                        padding: 0;
                        li{
                            position: relative;
                            display: block;
                            width: 100%;
                            border: none;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 14px;
                            margin-top: 10px;
                            padding-left: 18px;

                            @media (max-width: 991.98px) {
                              margin-bottom: 0px;
                            }

                            @media (max-width: 565.98px) {
                              font-size:15px;
                              line-height: 1.5;
                            }

                            &:first-child{
                                margin-top: 0px;
                            }
                            &:after{
                                @include position-absolute;
                                content: '';
                                top: 4px;
                                left: 4px;
                                width: 6px;
                                height: 6px;
                                border: 1px solid #283A5D;
                                border-radius: 2px;
                            }
                            strong{
                                font-size: 12px;

                                @media (max-width: 565.98px) {
                                  font-size:15px;
                                  line-height: 1.5;
                                }
                            }

                        }
                    }
                    .data-row{
                      @include position-relative;
                      @include common-flex-jsb;
                      margin: 12px 0 0 0;
                      align-items: flex-start;
                      flex-direction: column;
                      .head-data{
                          @include position-relative;
                          @include common-flex;

                          p{
                              font-weight: normal;
                              font-size: 12px;
                              line-height: 12px;
                              margin:0 0 0 7px;
                              @media (max-width: 565.98px) {
                                font-size:15px;
                                font-weight:500;
                              }
                              strong{
                                  font-weight: 500;
                              }
                              a{
                                  color: $blue-color;
                              }
                          }
                      }
                      .sub-data{
                        @include position-relative;
                        padding-left: 20px;
                        margin-top: 6px;
                        .date-range{
                          @include position-relative;
                          font-weight: normal;
                          font-size: 12px;
                          line-height: 12px;
                          margin: 3px 0;
                          @media (max-width: 565.98px) {
                            font-size:15px;
                            line-height:15px;
                            margin:5px 0px;
                          }
                        }
                      }


                  }

                }
                &-bottom-section{
                    @include position-relative;
                    padding: 10px 14px;
                    min-height: 30px;
                    background-color: #F3F7FF;
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                    .data-row-bottom{
                        @include position-relative;
                        @include common-flex;
                        margin-top: 8px;
                        &:first-child{
                            margin-top: 0px;
                        }
                        p{
                            margin: 0 0 0 5px;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            letter-spacing: -0.222222px;
                        }

                    }

                    &.priority-emergency{
                        background-color:#fff3f5;
                        color: #fd1f1f;
                        text-align: center;
                        @include common-flex-jc;
                        .data-row-bottom{
                            justify-content:center;
                            p{
                                font-weight: 500;
                                font-size: 10px;
                                line-height: 14px;
                            }
                        }
                    }
                    &.priority-high{
                      background-color:#fff8f1;
                      color: #ff9600;
                      text-align: center;
                      @include common-flex-jc;
                      .data-row-bottom{
                          justify-content:center;
                          p{
                              font-weight: 500;
                              font-size: 10px;
                              line-height: 14px;
                          }
                      }
                  }
                    &.priority-normal{
                      background-color:#F3F7FF;
                      text-align: center;
                      @include common-flex-jc;
                      .data-row-bottom{
                          justify-content:center;
                          p{
                              font-weight: 500;
                              font-size: 10px;
                              line-height: 14px;
                          }
                      }
                  }
                }
            }
          }


        &.co-ordinating{
            .jobs-heading{
                background-color:#f4f8ff;
                &-icon{
                    background-color:$co-ordinate;
                }
            }
            .jobs-card{
                background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%);
            }
        }

        &.waiting{
            .jobs-heading{
                background-color: lighten($waiting, 34%);
                &-icon{
                    background-color:$waiting;
                }
            }
            .jobs-card{
                background: linear-gradient(180deg, #ECF2FF 2.22%, #FFFFFF 100%);
            }
        }
        &.scheduled{
            .jobs-heading{
                background-color: lighten($scheduled, 20%);
                &-icon{
                    background-color:$scheduled;
                }
            }
            .jobs-card{
                background: linear-gradient(180deg, #F6ECFF 0%, #FFFFFF 100%);
            }
        }
        &.work-in-progress{
            .jobs-heading{
                background-color: lighten($work-in-progress, 44%);
                &-icon{
                    background-color:$work-in-progress;
                }
            }
            .jobs-card{
                background: linear-gradient(180deg, #EFFFF9 0%, #FFFFFF 100%);
            }

        }
        &.paused{
            .jobs-heading{
                background-color: lighten($paused, 48%);
                &-icon{
                    background-color:$paused;
                }
            }
            .jobs-card{
                background: linear-gradient(180deg, #FFF7E9 1.53%, #FFFFFF 99.29%);
            }
        }
        &.qip{
          .jobs-heading{

              &-icon{
                  background-color:#eee;
              }
          }
      }
        .jobs-status{
            @include position-relative;
            border-radius: 6px;
            font-weight: 500;
            font-size: 11px;
            line-height: 13px;
            color: $white-color;
            padding: 6px 9px;
            padding-top:7px;
            height: 25px !important;
            @media (max-width: 565.98px) {
              padding-top:0px;
              padding-bottom: 0px;
              font-size:15px;
              height:28px !important;
              line-height:28px !important;
            }
            img{
                margin-right: 5px;
            }
            svg{
              margin-right: 5px;
              margin-top:-2px;
            }
            &.enrouted{
                background-color:#283A5D;
                -webkit-animation: progress-bar-stripes 2s linear infinite;
                -o-animation: progress-bar-stripes 2s linear infinite;
                animation: progress-bar-stripes 2s linear infinite;
                background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
                background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
                background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
                background-size: 40px 40px;
                height: 20px;
                display: flex;
                align-items: center;
              &.stop{
                background-image: none !important;
              }
            }
            &.enroute-stopped{
              background-color:#FF5D5D;
            }
            &.reached{
                background-color:#3E7CFF;
            }
            &.started{
                background-color:#48C898;
                -webkit-animation: progress-bar-stripes 2s linear infinite;
                -o-animation: progress-bar-stripes 2s linear infinite;
                animation: progress-bar-stripes 2s linear infinite;
                background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
                background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
                background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
                background-size: 40px 40px;
                height: 20px;
                display: flex;
                align-items: center;
                &.stop{
                  background-image: none !important;
                }
            }
            &.stopped{
              background-color:#FF5D5D;
            }
            &.pending, &.waiting {
              background-color: #EEEEEE;
              color: #283A5D;
              font-weight: 400;
            }
            &.to-be-billed, &.kick-back-to-pro {
              background-color: rgba(255, 93, 93, 0.07);
              -webkit-animation: progress-bar-stripes 2s linear infinite;
              -o-animation: progress-bar-stripes 2s linear infinite;
              animation: progress-bar-stripes 2s linear infinite;
              background-image: linear-gradient( 45deg, rgba(255, 93, 93, 0.07) 25%, transparent 25%, transparent 50%, rgba(255, 93, 93, 0.07) 50%, rgba(255, 93, 93, 0.07) 75%, transparent 75%, transparent);
              background-size: 40px 40px;
              height: 40px !important;
              display: flex;
              align-items: center;
              color: #FF5D5D;
              .billed-msg-outer{
                @include position-relative;
                @include common-flex;
              }
            }
            &.paused, &.submitted-for-customer-approval, &.quote-submitted {
              background-color: rgba(253, 142, 37, 0.1);
              @include common-flex-jsb;
              font-weight: 500;
              color: #FD8E25;
              a{
                color: #1376FB;
                font-weight: 500;
                font-size: 11px;
                line-height: 13px;
              }
            }
        }



    }
    &.open-make-ready-jobs{
      li{
        width: calc(100% / 4);
        @media(max-width:991.98px){
          width: 100%;
        }
        .mrc-jobs-status {
          @include position-relative;
          background-color: r$light-grey-50;
          display: flex;
          align-items: flex-start;
          gap: 5px;
          padding: 10px;
          border-radius: 6px;

          &.claimed,
          &.admin-assigned,
          &.waiting-for-pro-acknowledgement {
            background-color: $scheduled;
          }

          &.enroute-stopped {
            background-color: #FF5D5D;
          }

          &.reached {
            background-color: #3E7CFF;
          }

          &.started {
            background-color: $work-in-progress;
          }

          &.stopped {
            background-color: #FF5D5D;
          }
          &.go-back-in-progress {
            background-color: #1B67FF;
          }

          &.to-be-billed {
            background-color: rgba(255, 93, 93, 0.07);
            -webkit-animation: progress-bar-stripes 2s linear infinite;
            -o-animation: progress-bar-stripes 2s linear infinite;
            animation: progress-bar-stripes 2s linear infinite;
            background-image: linear-gradient(45deg, rgba(255, 93, 93, 0.07) 25%, transparent 25%, transparent 50%, rgba(255, 93, 93, 0.07) 50%, rgba(255, 93, 93, 0.07) 75%, transparent 75%, transparent);
            background-size: 40px 40px;
            height: 40px !important;
            display: flex;
            align-items: center;
            p{
              color: #FF5D5D;
            }

            .billed-msg-outer {
              @include position-relative;
              @include common-flex;
            }
          }

          &.paused {
            background-color: #FF8E00;
            a {
              color: $white-color;
              font-weight: 500;
              font-size: 11px;
              line-height: 13px;
            }
          }

          i {
            display: flex;
            align-items: center;
            position: relative;
            top: 2px;
          }

          p {
            margin: 0;
            line-height: 1;
            font-weight: 500;
            font-size: 15px;
            color: $white-color;
          }

          &.walk-thru {
            background-color: $paused;
          }
          &.quote-request{
            background-color: #E6E6E6;
            p{
              color: #444;
            }
          }
          &.pending-approval{
            background-color: #FF8E00;
            p{
              color: #fff;
            }
            svg{
              path{
                stroke: #fff;
              }
            }
          }
          &.approved-pending-scheduling{
            background-color: #D6EFE6;
            p{
              color: #444;
            }
            svg{
              path{
                stroke: #444;
              }
            }
          }
        }
        .wo-section{
          @include common-flex-jsb;
          @include position-relative;
          margin-top: 6px;
          &--left{
            @include common-flex;
            font-size: 12px;
            color: $text-color;
            gap: 5px;
            align-items: flex-start;
            svg{
              flex-shrink: 0;
              position: relative;
              top: 2px;
            }
            a{
              color: $blue-color;
              background: transparent;
              text-decoration: none;
              word-break: break-word;
            }
          }
          &--right{
            .job-id{
              background-color: #3E7CFF15;
              border-radius: 6px;
              font-weight: 500;
              font-size: 12px;
              line-height: 21px;
              padding: 2px 6px;
              min-height: 22px;
              color: #3e7cff;
            }
          }
        }
        .no-data-found{
          min-height: calc(100vh - 300px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .view-scope{
        @include position-relative;
        padding: 0 12px 12px;
        button{
          width:100%;
          border-style: none;
          background: #FFFFFF;
          border: 1px solid #D7E1F4;
          border-radius: 7px;
          color: #1376fb !important;
          font-size: 13px;
          text-align: center;
          padding: 6px 0;
          @include common-flex-jc;
          &:active{
            background: #FFFFFF !important;
            border: 1px solid #D7E1F4 !important;
          }
        }
        .review-quote-btn{
          background-color: $primary-color !important;
          border-radius: 7px;
          font-weight: normal;
          font-size: 13px;
          line-height: 18px;
          color: $white-color !important;
          border: none;
          padding: 6px 0px;
          transition: all .3s;
          border: none !important;
          margin-bottom: 10px;
          &:hover{
              color: $white-color;
              opacity: 0.7;
              background-color: $primary-color;
          }
          &:focus{
              color: $white-color !important;
              opacity: 1.0;
              background-color: $primary-color !important;
              box-shadow: none !important;
          }
          &:active{
              color: $white-color !important;
              background-color: $primary-color !important;
          }
          &:disabled{
            color: $white-color !important;
            background-color: $primary-color !important;
            opacity: 1;
            cursor: not-allowed;
        }
        }
        .job-notes-btn {
          margin-top: 13px;
          background: rgba(19, 118, 251, 0.1);
          position: relative;
          &.notification-dot{
            &:after{
              position: absolute;
              content: '';
              border-radius: 10px;
              border: 2px solid #FFF;
              background: #FF4949;
              top: -3px;
              right: -3px;
              width: 10px;
              height: 10px;
            }

          }
        }
      }
    }
}

.accordion-arrow{
  transform: rotate(90deg);
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
.actions-outer-wrapper{
  @include position-relative;
  @include common-flex-jc;
}
.scope-data{
  @include position-relative;
  max-height: 350px;
  margin: 20px 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #eaeff4;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #b8c6d4;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #b8c6d4;
  }
  ul{
    @include position-relative;
    margin: 0;
    padding: 0;
    li{
      @include position-relative;
      padding-left: 25px;
      color: $text-color;
      font-size: 16px;
      margin-bottom: 15px;
      &:after{
        @include position-absolute-fl;
        content: '';
        width: 8px;
        height: 8px;
        top: 8px;
        left: 0;
        border-radius: 2px;
        background: #1376FB;
        mix-blend-mode: normal;
        opacity: 0.3;
      }
    }
  }
}


.open-job-action-buttons{
  @include position-relative;
  @include common-flex;
  padding: 0 10px 10px 10px;
  gap: 10px;
  .ask-question{
    padding: 0;
    .btn{
        all: unset;
    }
    .job-notes{
      background: rgba(19, 118, 251, 0.1);
      border-style: none;
      border-radius: 7px;
      color: #1376FB !important;
      font-size: 13px;
      position: relative;
      width: 100%;
      float: left;
      text-align: center;
      padding: 5px 0;
      cursor: pointer;
      &:hover{
        opacity: .9;
      }
      &.notification-dot{
        &:after{
          position: absolute;
          content: '';
          border-radius: 10px;
          border: 2px solid #FFF;
          background: #FF4949;
          top: -3px;
          right: -3px;
          width: 10px;
          height: 10px;
        }

      }
    }
  }
}

.job-note-action{
  @include common-flex-jc;
  padding-bottom: 30px;
}
