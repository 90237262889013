a{
    transition: all .4s;
    &:hover{
        opacity: .8;
        text-decoration: none;
    }
}

b, strong{
  font-weight:500;
}

.hr{
    margin: 50px 0 !important;
    border-top: 2px solid #EFF1F6;

    @media (max-width: 991.98px) {
      margin:20px 0px !important;
    }
}
.hr-md{
  @include position-relative;
  margin: 30px 0 !important;
  border-top: 1px solid #EFF1F6;
}
.hr-small {
    margin: 7px 0 22px 0;
}
.hr-small2 {
    margin: 0px 0 32px 0;
}
.color--white{
    color: #fff !important;
}
.outline-red{
    outline: solid 1px red;
}
.hr-dashed{
    border: dashed 1px #D8D8D8;
}
.hr-solid {
    border: solid 1px #D8D8D8;
    opacity: 0.3;
}
.mt-10{
    margin-top:26px;
}
.hr-dotted{
    border: dashed 1px #ccc;
    margin:29px 0 6px 0;
}
.hr-sidebar{
    margin:29px 0
}
.border-0{
    border: none !important;
}
.no-bg{
    background: transparent !important;
}
.no-shadow{
    box-shadow: unset !important;
}
.rounded-img{
    border-radius: 50%;
}
body .height-auto{
  height: auto !important;
  min-height: auto !important;
}
*{
  &:focus{
    outline:none !important;
  }
}
.no-data{
  @include position-relative;
  margin: 30px 0;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.something-wrong{
  @include position-relative;
  height: 100vh;
  @include common-flex-jc;
  text-align: center;
  h3{
    margin-top:10px;
    color: #EE5555;
    font-weight: 400;
  }
  &--inner{
    position: relative;
    padding: 50px;
    border: solid 1px #ffa7a7;
    border-radius: 10px;
  }
}
.cw-25{
  width: 17%;
}
.sw-9{
  width: 9%;
}
.carousel{
  .slide{
    background-color: #000;
  }
}
